export const OPERATORS_SAVE = "OPERATORS_SAVE"
export const OPERATORS_SET_PAGE = "OPERATORS_SET_PAGE"
export const OPERATORS_SET_SEARCH = "OPERATORS_SET_SEARCH"
export const OPERATORS_LOCK = "OPERATORS_LOCK"
export const OPERATORS_TRIGGER = "OPERATORS_TRIGGER"
export const OPERATORS_TRIGGER_TEAM = "OPERATORS_TRIGGER_TEAM"

export const operatorsSave = (team, data) => ({
  type: OPERATORS_SAVE,
  payload: { team, data },
})

export const operatorsLock = (team, data) => ({
  type: OPERATORS_LOCK,
  payload: { team, data },
})

export const operatorsSetPage = (team, data) => ({
  type: OPERATORS_SET_PAGE,
  payload: { team, data },
})

export const operatorsSetSearch = data => ({
  type: OPERATORS_SET_SEARCH,
  payload: { data },
})

export const operatorsTrigger = () => ({
  type: OPERATORS_TRIGGER,
})
