import React from "react"
import { ModalProvider } from "react-modal-hook"
import { Provider, useSelector } from "react-redux"
import { ToastProvider } from "react-toast-notifications"

import ApolloClient from "apollo-boost"
import fetch from "isomorphic-fetch"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import { ThemeProvider } from "styled-components"

import { ApolloProvider } from "@apollo/react-hooks"

import ErrorMsg from "@atoms/error"
import ToastAtom from "@atoms/toast"

import { getUserToken } from "@store/account/selectors"
import { getTenant } from "@store/tenant/selectors"

import { GraphQLError } from "@utils/classes"
import Logout from "@utils/Logout"

import theme from "@styles/theme"

import { getCurrentLanguage } from "@locales"
import store from "@store"

import { changeLanguage } from "./locales"

const persistor = persistStore(store)
if (typeof window !== "undefined") window.persistor = persistor

const { endpoints } = require("@store/endpoints")
const uri = endpoints().POST.query

const Themed = ({ Element, expired, error, errorParams, cleanError }) => {
  const tenant = useSelector(getTenant)
  const { brandColorPrimary, brandColorAccent } = tenant ? tenant : {}

  return (
    <ThemeProvider theme={theme(brandColorPrimary, brandColorAccent)}>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={3000}
        components={{ Toast: ToastAtom }}
        placement="bottom-left"
      >
        <ModalProvider>
          {Element}
          {expired && <Logout expired={expired} />}
          {error && (
            <ErrorMsg
              name={error}
              params={errorParams}
              toast
              onToastEnd={cleanError}
            />
          )}
        </ModalProvider>
      </ToastProvider>
    </ThemeProvider>
  )
}

// eslint-disable-next-line react/display-name,react/prop-types
class Wrap extends React.Component {
  constructor(props) {
    super(props)

    this.element = props.element
    this.state = { expired: false, error: null, errorParams: {} }
    this.storeState = store.getState()

    const currentLang = getCurrentLanguage()
    changeLanguage(currentLang)

    this.client = new ApolloClient({
      uri,
      fetch,
      request: operation => {
        const state = store.getState()
        const token = getUserToken(state, true)

        operation.setContext({
          headers: {
            authorization: `Bearer ${token}`,
            lng: currentLang,
          },
        })
      },
      onError: ({ graphQLErrors, errors }) => {
        if (graphQLErrors && graphQLErrors.length) {
          const state = store.getState()
          const token = getUserToken(state, true)

          const { name, message, path } = !token
            ? { message: null }
            : new GraphQLError(graphQLErrors)

          const expirablePath = [
            "allUsers",
            "allTeams",
            "allEvents",
            "_allEventsMeta",
            "allRequests",
            "_allRequestsMeta",
            "allWorkingShifts",
          ]
          const isExpirable = expirablePath.indexOf(path) > -1

          const expired = name === "AccessDeniedError" && isExpirable && token

          // if (expired) location.reload()

          this.setState({
            ...this.state,
            expired,
            error: expired ? null : message || name,
            errorParams: expired ? null : { path, message },
          })
          this.setState({ ...this.state, expired: false }) // return to default state after triggering logout
        }
      },
    })
  }

  cleanError = () => {
    this.setState({ ...this.state, error: null })
  }

  render() {
    const { client, state, element, cleanError, storeState } = this
    const { expired, error, errorParams } = state

    const Element = React.cloneElement(element)

    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <PersistGate loading={null} persistor={persistor}>
            <Themed
              Element={Element}
              expired={expired}
              error={error}
              errorParams={errorParams}
              cleanError={cleanError}
            />
          </PersistGate>
        </ApolloProvider>
      </Provider>
    )
  }
}

export default Wrap
