import { css } from "styled-components"

export const fontStyle = (type = "normal", weight = "normal") => {
  const fontProperties = css`
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights[weight]};
  `

  if (type === "huge") {
    return css`
      ${fontProperties}
      font-size: 20px;
      line-height: 1.33;

      @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 28px;
        line-height: 1.14;
      }
    `
  } else if (type === "big") {
    return css`
      ${fontProperties}
      font-size: 14px;
      line-height: 1.4;

      @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 20px;
      }
    `
  } else if (type === "large") {
    return css`
      ${fontProperties}
      font-size: 18px;
      line-height: 1.33;
    `
  } else if (type === "normal") {
    return css`
      ${fontProperties}
      font-size: 12px;
      line-height: 1.25;

      @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 16px;
      }
    `
  } else if (type === "medium") {
    return css`
      ${fontProperties}
      font-size: 20px;
      line-height: 1.4;
    `
  } else if (type === "small") {
    return css`
      ${fontProperties}
      font-size: 14px;
    `
  } else if (type === "tiny") {
    return css`
      ${fontProperties}
      font-size: 12px;
      line-height: 1.33;
    `
  } else if (type === "extra-small") {
    return css`
      ${fontProperties}
      font-size: 8px;
      line-height: 1.5;
    `
  } else {
    return css`
      ${fontProperties}
      font-size: 16px;
      line-height: 1.25;
    `
  }
}
