import React, {
  useEffect,
  useState
} from "react"
import { useTranslation } from "react-i18next"

import classnames from "classnames"

import Button from "@atoms/button"
import Icon from "@atoms/icon"
import MarkdownText from "@atoms/markdowntext"

import { CountDownStyled, ToastStyled } from "./toast.styled"

let interval

// NOTE: invoke animation when NOT `autoDismiss` with opacity of 0 to avoid a
// paint bug in FireFox.
// https://bugzilla.mozilla.org/show_bug.cgi?id=625289
const Countdown = ({
  autoDismissTimeout,
  opacity,
  appearance,
  isRunning,
  ...props
}) => (
  <CountDownStyled
    className={classnames("countdown", appearance)}
    autoDismissTimeout={autoDismissTimeout}
    opacity={opacity}
    isRunning={isRunning}
    {...props}
  />
)

const Toast = ({
  appearance,
  autoDismiss,
  autoDismissTimeout,
  onDismiss,
  isRunning,
  children,
}) => {
  const { t } = useTranslation()
  const [dismissing, setDismissing] = useState(false)

  const toastAnimDuration = 250

  let iconName
  switch (appearance) {
    case "success":
      iconName = "check"
      break
    case "info":
    case "error":
      iconName = "alert"
      break
  }

  const dismiss = () => {
    setDismissing(true)
    setTimeout(() => onDismiss(), toastAnimDuration + 10)
  }

  useEffect(() => {
    interval = setTimeout(
      () => setDismissing(true),
      autoDismissTimeout - toastAnimDuration - 10
    )
    return () => clearInterval(interval)
  }, [])

  return (
    <ToastStyled
      className={classnames(appearance)}
      toastAnimDuration={toastAnimDuration}
      dismissing={dismissing}
    >
      <div className={"side"}>
        <Icon className={"icon"} name={iconName} />
      </div>
      <div className={"message"}>
        <MarkdownText>{children}</MarkdownText>
      </div>
      <Button className={"button"} onClick={dismiss}>
        <div>{t("close")}</div>
        <Countdown
          opacity={autoDismiss ? 1 : 0}
          autoDismissTimeout={autoDismissTimeout}
          isRunning={isRunning}
          appearance={appearance}
        />
      </Button>
    </ToastStyled>
  )
}

export default Toast
