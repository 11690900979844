import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"

import { navigate } from "gatsby"

import { recoveringSave, userCleanup, userUpdate } from "@store/account/actions"
import { getRefreshToken } from "@store/account/selectors"
import fetcher from "@store/fetcher"
import { fetcherCleanup } from "@store/fetcher/actions"
import { getFetch } from "@store/fetcher/selectors"
import { tenantCleanup } from "@store/tenant/actions"

let forcedLogout = false

const Logout = ({
  expired,
  refreshToken: rt,
  to = "/",
  recovering = false,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const { isLoading } = useSelector(state =>
    getFetch(state, "POST.auth.refreshToken")
  )

  const dispatch = useDispatch()
  const _fetcher = (path, options, callbacks) =>
    dispatch(fetcher(path, options, callbacks))
  const _fetcherCleanup = () => dispatch(fetcherCleanup())
  const _userCleanup = () => dispatch(userCleanup())
  const _tenantCleanup = () => dispatch(tenantCleanup())
  const _userUpdate = data => dispatch(userUpdate(data))
  const _recoveringSave = data => dispatch(recoveringSave(data))

  const refreshToken = rt || useSelector(getRefreshToken)

  const [isRefreshing, setRefreshing] = useState(false)

  const logoutCleanup = async () => {
    // if (isLoading) return
    setRefreshing(false)
    _userCleanup()
    _tenantCleanup()
    _fetcherCleanup()
    window.socketSessionStarted = false
    window.socket && window.socket.disconnect()
    navigate(to)

    if (forcedLogout) return
    addToast(t("toasts.forced_logout"), {
      appearance: "error",
      autoDismiss: true,
    })
    forcedLogout = true

    // limit logouts toasts in a span of 3 seconds
    setTimeout(() => {
      forcedLogout = false
    }, 3000)
  }

  const onFetchRefreshToken = {
    success: data => {
      const { token, refreshToken, item } = data
      if (recovering) {
        _userCleanup()
        _recoveringSave({ token, ...item })
      } else {
        _userUpdate({ token, refreshToken, ...item })
      }
      setRefreshing(false)
    },
    error: err => {
      logoutCleanup()
    },
  }

  const checkRefreshToken = () => {
    // if (isLoading) return
    const options = {
      headers: { Authorization: `Bearer ${refreshToken}` },
    }

    if (!isRefreshing && refreshToken) {
      setRefreshing(true)

      _fetcher("POST.auth.refreshToken", options, onFetchRefreshToken)
    }
  }

  const checkExpired = () => {
    if (expired && refreshToken) {
      checkRefreshToken()
    } else {
      logoutCleanup()
    }
  }

  useEffect(() => {
    checkExpired()
  }, [expired, refreshToken])

  return null
}

export default Logout
