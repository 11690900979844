import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "@store/index";
import { uploadFile } from "@thunks/cloudThunk";
import { AxiosResponse } from "axios";

enum UploadStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  LOADED = 'loaded',
  FAILED = 'failed',
}

interface FileStatus {
  uploadStatus: UploadStatus,
  uploadProgress: number,
  uploadStep: number,
  uploadError: string,
  fileName: string,
}

const initialState = {
  uploadStatus: UploadStatus.IDLE,
  uploadProgress: 0,
  uploadStep: 0,
  uploadError: '',
  fileName: '',
} as FileStatus;

const uploadSlice = createSlice({
    name: 'UPLOAD',
    initialState,
    reducers: {
      setUploadStep: (state, action: PayloadAction<number>) => {
        state.uploadStep = action.payload;
      },
      setUploadProgress: (state, action: PayloadAction<number>) => {
        state.uploadProgress = action.payload;
      },
      setFileName: (state, action: PayloadAction<string>) => {
        state.fileName = action.payload;
      },
      resetUploadStatus: (state) => {
        Object.entries(initialState).forEach(([key, value]) => state[key] = value);
      }
    },
    extraReducers: (builder) => {
        builder
          .addCase(uploadFile.pending, (state) => {
            state.uploadStatus = UploadStatus.LOADING;
          })
          .addCase(uploadFile.fulfilled, (state, action: PayloadAction<AxiosResponse>) => {
            const { data } = action.payload;
            if (!data) {
              // TODO: Check how to pass only error correctly and not whole object
              state.uploadError = JSON.stringify(action.payload);
              state.uploadStatus = UploadStatus.FAILED;
            } else {
              state.uploadStatus = UploadStatus.LOADED;
            }
          })
          .addCase(uploadFile.rejected, (state) => {
            state.uploadError = 'File upload rejected';
            state.uploadStatus = UploadStatus.FAILED;
          });
    },
});

const selectUploadStep = createSelector(
  [(state: RootState) => state.upload],
  ({ uploadStep }) => uploadStep,
);

const selectUploadError = createSelector(
  [(state: RootState) => state.upload],
  ({ uploadError }) => uploadError,
);

const selectUploadProgress = createSelector(
  [(state: RootState) => state.upload],
  ({ uploadProgress }) => uploadProgress,
);

const selectIsFirstUploadStep = createSelector(
  [selectUploadStep],
  (uploadStep) => uploadStep === 0,
);

const selectIsUploadLoading = createSelector(
  [(state: RootState) => state.upload],
  ({ uploadStatus }) => uploadStatus === UploadStatus.LOADING,
);

const selectUploadingFileName = createSelector(
  [(state: RootState) => state.upload],
  ({ fileName }) => fileName,
);

export const {
  setFileName,
  setUploadStep,
  setUploadProgress,
  resetUploadStatus,
} = uploadSlice.actions;
export { 
  selectUploadStep,
  selectUploadError,
  selectIsUploadLoading,
  selectIsFirstUploadStep,
  selectUploadProgress,
  selectUploadingFileName,
};
export default uploadSlice.reducer;
