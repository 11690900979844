import { lighten, transparentize } from "polished"
import styled, { css } from "styled-components"

import { fontStyle } from "@styles/fonts"

/** MIXINS */
export const buttonStyle = css`
  width: auto;
  all: unset;
  cursor: pointer;
  padding: 16px 24px;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.borderRadiusSmall};
  text-align: center;
  text-transform: uppercase;
  position: relative;
  user-select: none;
  transition: 0.3s background-color, 0.3s color;
  ${fontStyle("small", "bold")};

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVertical}) {
    padding: 8px 12px;
    font-size: 12px;
  }

  &.full {
    width: 100%;
    box-sizing: border-box;
  }

  &.small {
    padding: 8px;
    font-size: 13px;
    line-height: 17px;
  }

  span,
  .icon {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    color: ${({ theme }) => theme.palette.common.white};
    line-height: 0px;
  }

  svg {
    fill: ${({ theme }) => theme.palette.common.white};
  }

  .icon {
    &.iconStart {
      margin-right: 8px;
    }

    &.iconEnd {
      margin-left: 8px;
    }
  }

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &,
  &:active,
  &:focus {
    outline: none;
  }

  &.loading {
    span,
    .icon {
      opacity: 0;
    }
  }

  &.animated {
    /* animation: zoomInFade 0.25s forwards; */
  }

  &:active {
    transform: translateX(1px) translateY(1px);
  }

  .loader {
    svg {
      margin: auto !important;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .hidden {
    opacity: 0 !important;
  }

  ${({ theme }) => {
    const maps = theme.palette.maps.common
    let styles = ""

    for (const variant in maps) {
      const hoverBackgroundColor = ""

      if (maps[variant].background == "transparent") {
        hoverBackgroundColor = transparentize(
          0.1,
          theme.palette.common.deepSapphire
        )
      } else if (maps[variant].background == theme.palette.common.white) {
        hoverBackgroundColor = theme.palette.common.paleGrey
      } else if (maps[variant].background != theme.palette.common.white) {
        hoverBackgroundColor = lighten(0.06, maps[variant].background)
      }

      styles += `
        &.${variant} {
          background-color: ${maps[variant].background};
          padding: ${maps[variant].padding};
          text-transform: ${maps[variant].textTransform};
          ${maps[variant].border && `border: ${maps[variant].border}`};

          span {
            color: ${maps[variant].color};
            -webkit-text-fill-color: ${maps[variant].color};
            -webkit-opacity: 1;
          }

          svg {
            fill: ${maps[variant].color};
          }

          .loader {
            svg path {
              stroke: ${maps[variant].color};
            }
          }

          &:not(.disabled):hover {
            background-color: ${maps[variant].hoverBackground ||
              hoverBackgroundColor}
          }
        }
      `
    }

    return css`
      ${styles}
    `
  }}

  ${({ theme }) => {
    const maps = theme.palette.maps.contrast
    let styles = ""

    for (const variant in maps) {
      styles += `
        &.text-${variant} {
          color: ${maps[variant]}
        }
      `
    }

    return css`
      ${styles}
    `
  }}

  
  &.disabled {
    pointer-events: none;
    background-color: ${({ theme }) => theme.palette.common.veryLightPink};

    span {
      color: ${({ theme }) => theme.palette.common.brownGrey};
      -webkit-text-fill-color: ${({ theme }) => theme.palette.common.brownGrey};
      -webkit-opacity: 1;
    }

    svg,
    svg path {
      stroke: ${({ theme }) => theme.palette.common.brownGrey} !important;
    }
  }
`

/** EXPORT */
export const ButtonStyled = styled.button`
  ${buttonStyle}
`
