import React from "react"

import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"

import Alert from "./icons/alert.inline.svg"
import Back from "./icons/back.inline.svg"
import CalendarCancelled from "./icons/calendar-cancelled.inline.svg"
import CalendarEdit from "./icons/calendar-edit.inline.svg"
import CalendarError from "./icons/calendar-error.inline.svg"
import CalendarExpired from "./icons/calendar-expired.inline.svg"
import CalendarExpired2 from "./icons/calendar-expired2.inline.svg"
import CalendarFinished from "./icons/calendar-finished.inline.svg"
import CalendarNew from "./icons/calendar-new.inline.svg"
import CalendarReady from "./icons/calendar-ready.inline.svg"
import CalendarSuccess from "./icons/calendar-success.inline.svg"
import CalendarWaiting from "./icons/calendar-waiting.inline.svg"
import Calendar from "./icons/calendar.inline.svg"
import CallNew from "./icons/call-new.inline.svg"
import Call from "./icons/call.inline.svg"
import Calling from "./icons/calling.inline.svg"
import Check from "./icons/check.inline.svg"
import ChevronDown from "./icons/chevron-down.inline.svg"
import Close from "./icons/close.inline.svg"
import Closed from "./icons/closed.inline.svg"
import EyeHide from "./icons/eye-hide.inline.svg"
import Eye from "./icons/eye.inline.svg"
import Help from "./icons/help.inline.svg"
import History from "./icons/history.inline.svg"
import List from "./icons/list.inline.svg"
import Lost from "./icons/lost.inline.svg"
import MoreBlue from "./icons/more-blue.inline.svg"
import More from "./icons/more.inline.svg"
import Notifications from "./icons/notifications.inline.svg"
import OperatorDeleted from "./icons/operator-deleted.inline.svg"
import Operator from "./icons/operator.inline.svg"
import Phone from "./icons/phone.inline.svg"
import Realtime from "./icons/realtime.inline.svg"
import Refresh from "./icons/refresh.inline.svg"
import Search from "./icons/search.inline.svg"
import Send from "./icons/send.inline.svg"
import Settings from "./icons/settings.inline.svg"
import Teams from "./icons/teams.inline.svg"
import Tour from "./icons/tour.inline.svg"
import UserAdd from "./icons/user-add.inline.svg"
import Waiting from "./icons/waiting.inline.svg"
import Web from "./icons/web.inline.svg"

import Lang_de from "./icons/languages/de.inline.svg"
import Lang_en from "./icons/languages/en.inline.svg"
import Lang_es from "./icons/languages/es.inline.svg"
import Lang_fr from "./icons/languages/fr.inline.svg"
import Lang_it from "./icons/languages/it.inline.svg"
import Lang_ja from "./icons/languages/ja.inline.svg"
import Lang_nl from "./icons/languages/nl.inline.svg"
import Lang_pl from "./icons/languages/pl.inline.svg"
import Lang_ru from "./icons/languages/ru.inline.svg"
import Lang_zh from "./icons/languages/zh.inline.svg"

import CreditCardAmex from "./icons/pay/credit-card-amex.inline.svg"
import CreditCardDiscover from "./icons/pay/credit-card-discover.inline.svg"
import CreditCardJCB from "./icons/pay/credit-card-jcb.inline.svg"
import CreditCardMastercard from "./icons/pay/credit-card-mastercard.inline.svg"
import CreditCardUnionPay from "./icons/pay/credit-card-unionpay.inline.svg"
import CreditCardVisa from "./icons/pay/credit-card-visa.inline.svg"
import CreditCard from "./icons/pay/credit-card.inline.svg"

const ICONS = new Map([
  ["alert", Alert],
  ["back", Back],
  ["check", Check],
  ["chevron-down", ChevronDown],
  ["eye", Eye],
  ["eye-hide", EyeHide],
  ["call-new", CallNew],
  ["call", Call],
  ["calling", Calling],
  ["calendar", Calendar],
  ["calendar-new", CalendarNew],
  ["calendar-edit", CalendarEdit],
  ["calendar-success", CalendarSuccess],
  ["calendar-finished", CalendarFinished],
  ["calendar-ready", CalendarReady],
  ["calendar-error", CalendarError],
  ["calendar-waiting", CalendarWaiting],
  ["calendar-expired", CalendarExpired],
  ["calendar-expired2", CalendarExpired2],
  ["calendar-cancelled", CalendarCancelled],
  ["closed", Closed],
  ["waiting", Waiting],
  ["lost", Lost],
  ["close", Close],
  ["list", List],
  ["more", More],
  ["more-blue", MoreBlue],
  ["notifications", Notifications],
  ["operator", Operator],
  ["operator-deleted", OperatorDeleted],
  ["teams", Teams],
  ["realtime", Realtime],
  ["refresh", Refresh],
  ["search", Search],
  ["settings", Settings],
  ["send", Send],
  ["help", Help],
  ["tour", Tour],
  ["history", History],
  ["user-add", UserAdd],
  ["web", Web],
  ["phone", Phone],

  ["credit-card-discover", CreditCardDiscover],
  ["credit-card-unionpay", CreditCardUnionPay],
  ["credit-card-jcb", CreditCardJCB],
  ["credit-card-amex", CreditCardAmex],
  ["credit-card-mastercard", CreditCardMastercard],
  ["credit-card-visa", CreditCardVisa],
  ["credit-card", CreditCard],

  ["lang-it", Lang_it],
  ["lang-en", Lang_en],
  ["lang-es", Lang_es],
  ["lang-fr", Lang_fr],
  ["lang-de", Lang_de],
  ["lang-nl", Lang_nl],
  ["lang-pl", Lang_pl],
  ["lang-ru", Lang_ru],
  ["lang-ja", Lang_ja],
  ["lang-zh", Lang_zh],
])

const NoopComponent = () => null

const propTypes = {
  /** Proptypes go here */
  /** One of available icons */
  name: PropTypes.string.isRequired,
}

const defaultProps = {
  /** Default Props go here */
}

const IconStyled = styled.svg`
  opacity: 1;
  &.invisible {
    opacity: 0;
  }
  path[fill="#0063FF"],
  g[fill="#0063FF"] {
    fill: ${({ theme }) => theme.palette.accent.main};
  }

  path[stroke="#0063FF"] {
    stroke: ${({ theme }) => theme.palette.accent.main};
  }
`

const Icon = props => {
  const { name, className, invisible = false, ...otherProps } = props
  const TheIcon = ICONS.get(name) || NoopComponent

  return (
    <>
      <IconStyled
        as={TheIcon}
        fill="currentColor"
        className={classnames(className, { invisible })}
        {...otherProps}
      />
    </>
  )
}

Icon.propTypes = propTypes
Icon.defaultProps = defaultProps

export default Icon
