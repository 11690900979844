import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { Connection } from "@opentok/client";
import { RootState } from "../../../core/store";

const connectionsAdapter = createEntityAdapter<Connection>(
  { selectId: ((device) => device.connectionId) }
);

const connectionSlice = createSlice({
    name: 'CONNECTIONS',
    initialState: connectionsAdapter.getInitialState(),
    reducers: {
        connectionAdded: {
            reducer: connectionsAdapter.addOne,
            prepare: (connection: Connection) => {
                const { connectionId, data, creationTime } = connection;
                return { payload: { connectionId, data, creationTime } };
            },
        },
        connectionRemoved: {
            reducer: connectionsAdapter.removeOne,
            prepare: (connection: Connection) => ({ payload: connection.connectionId }),
        },
    },
});

const {
    selectById: selectConnectionById,
} = connectionsAdapter.getSelectors((state: RootState) => state.connections);

export { selectConnectionById };
export const { connectionAdded, connectionRemoved } = connectionSlice.actions;
export default connectionSlice.reducer;
