import { subapps } from "@constants/enums"

const { setHours, setMinutes } = require("date-fns")

export const isValidDate = d => {
  const isValid = d instanceof Date && !isNaN(d)
  return isValid ? d : null
}

export const subapp = process.env.GATSBY_SUBAPP || subapps.HELP

export const addSeparators = obj => {
  let arr = []
  const keys = Object.keys(obj)
  const values = Object.values(obj)
  keys.map((item, index) => {
    const key = item
    const value = values[index]
    arr.push({
      text: key,
      separator: true,
    })
    arr = [...arr, ...value]
  })
  return arr
}

export const addSeparatorHeader = (arr, team, options = {}) => {
  const cloned = [...arr]

  cloned.splice(0, 0, {
    text: team,
    separator: true,
    ...options,
  })

  return cloned
}

export const pad = num => {
  const isNum = typeof num === "number"
  const isString = typeof num === "string"
  const isOne = (isNum && num <= 9) || (isString && num.length === 1)
  return isOne ? `0${num}` : num
}

export const isSameDate = (date1, date2) => {
  if (!date1 || !date2) return false

  const d1 = new Date(date1)
  const d2 = new Date(date2)
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

export const capitalize = str => {
  const lowercased = str.toLowerCase()
  const wordMap = lowercased.split(" ")

  return wordMap.map((word, i) => {
    const isLast = i === wordMap.length - 1
    return `${word[0].toUpperCase()}${word.substr(1)}${!isLast ? " " : ""}`
  })
}

export const numArray = (max, interval, labelInject = n => n) =>
  [...Array(max + 1).keys()]
    .filter(n => n > 0 && n % interval === 0)
    .map(n => ({
      label: labelInject(n),
      value: n,
    }))

export const getMidnight = (date, pseudoUTC = false, position = "start") => {
  if (!date) return
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const isEnd = position === "end"

  const midnight = pseudoUTC
    ? new Date(Date.UTC(year, month, day))
    : new Date(`${year}/${month + 1}/${day}`)

  return isEnd
    ? setHours(setMinutes(midnight, isEnd ? 59 : 0), isEnd ? 23 : 0)
    : midnight
}

export const getQueryParam = param => {
  const queryString = require("query-string")
  const loc = location && location.toString ? location.toString() : ""
  const params = (loc.split("?") || [])[1] || {}

  return queryString.parse(params)[param] || ""
}

export function delayer(t, v) {
  return new Promise(function(resolve) {
    setTimeout(resolve.bind(null, v), t)
  })
}

export const isWeb =
  typeof document !== "undefined" ? document.URL.match(/^https?:/i) : false
