export const OPEN_DIALOG = "OPEN_DIALOG"
export const CLOSE_DIALOG = "CLOSE_DIALOG"
export const SET_DIALOG_DATA = "SET_DIALOG_DATA"
export const SET_DIALOG_ON_CLOSE = "SET_DIALOG_ON_CLOSE"

export const openDialog = key => ({
  type: OPEN_DIALOG,
  payload: { key },
})

export const closeDialog = key => ({
  type: CLOSE_DIALOG,
  payload: { key },
})

export const setDialogData = (key, data) => ({
  type: SET_DIALOG_DATA,
  payload: { key, data },
})

export const setDialogOnClose = (key, fn) => ({
  type: SET_DIALOG_ON_CLOSE,
  payload: { key, fn },
})
