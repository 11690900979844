import axios from 'axios'
import objectPath from "object-path"
import { endpoints } from "@store/endpoints"

export const uploadDocumentApi = async ({
    sessionId,
    stream,
    setProgress,
}) => {
    const { onSuccess, onError, onProgress } = stream;

    const endpoint = objectPath.get(endpoints({}), "POST.upload_document")

    const body = new FormData();
    body.append("file", stream.file);

    try {
        return axios({
            method: 'post',
            url: `${endpoint}/${sessionId}`,
            data: body,
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        })

    } catch (e) {
        console.log(e);
        throw e;
    }
};
