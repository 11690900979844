import React, {
  useEffect,
  useRef
} from "react"
import Loader from "react-loader-spinner"

import classnames from "classnames"

// import { useAnimatePresence } from "use-animate-presence"
import { SpinnerStyled } from "./spinner.styled"

const isDev = process.env.NODE_ENV === "development"

const fadeableVariants = {
  opacity: {
    from: 0,
    to: 1,
  },
}

const Spinner = ({
  className,
  height = 100,
  width = 100,
  hidden,
  fullpage,
  spinnerOver,
  style = {},
  ...otherProps
}) => {
  const isFirstRender = useRef(true)

  // const animated = useAnimatePresence({
  //   variants: fadeableVariants,
  //   duration: hidden || !fullpage ? 400 : 0,
  //   initial: hidden ? "hidden" : "visible",
  // })

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    // animated.togglePresence()
  }, [hidden])

  // const visible = isDev ? !hidden && !fullpage : animated.isRendered && !hidden
  const visible = !hidden

  return (
    <>
      {visible && (
        <SpinnerStyled
          // ref={animated.ref}
          className={classnames(
            className,
            { fullpage, hidden, spinnerOver },
            "spinner"
          )}
        >
          <Loader
            type="TailSpin"
            height={height}
            width={width}
            visible={true}
            style={style}
            {...otherProps}
          />
        </SpinnerStyled>
      )}
    </>
  )
}

export default Spinner
