import { keyframes } from "styled-components"

export const animations = {
  fadeIn: keyframes`
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  `,
  fadeOut: keyframes`
    0% {
      opacity: 1;
    }

    20% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  `,
  zoomIn: keyframes`
    0% {
      transform: scale(0, 0);
    }

    20% {
      transform: scale(0, 0);
    }

    100% {
      transform: scale(1, 1);
    }
  `,
  errorShake: ({ theme }) => keyframes`
    0%,
    70% {
      background-color: ${theme.palette.common.red};
    }


    0%,
    100% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(2px, 0, 0);
    }

    100% {
      background-color: ${theme.palette.primary.main}
    }
  `,
}
