import OT from "@opentok/client";
import { RefObject } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { ChatMessage } from "../features/videoCall/slices/chatMessagesSlice";
import { VideoCreatedEvent } from "../features/videoCall/slices/streamSlice";

interface VoiceSizeParams {
    audioLevel: number,
    hasAudio: boolean,
    threshold: number,
    alpha: number,
    beta: number,
    min: number
    max?: number,
}

interface ClearInactiveEntityParams {
    lastUpdateRef: RefObject<number>,
    callback?: (deleted: boolean) => void,
    storageItems: string[],
    threshold: number,
    time: number,
}

export const getVoiceSize = ({audioLevel, hasAudio, threshold, alpha, beta, min, max = Infinity} : VoiceSizeParams) : number => {
    const isNoise = hasAudio && audioLevel < threshold;
    let voiceSize = 0;
    if (isNoise) voiceSize = min;
    else if (hasAudio) voiceSize = alpha * (Math.log((1 + audioLevel) * beta) + 1);
    return Math.min(voiceSize, max);
}

// NOTE: Opentok getDevices uses old callback syntax, re-export as promise based function
export const getDevices = async () => {
    return new Promise((resolve, reject) => {
        OT.getDevices((error, devices) => {
            if (error) reject(error);
            else resolve(devices || []);
        });
    })
}

// NOTE: returning true on success because returning MediaStream from getUserMedia
// triggers a redux warning. I do not need MediaStream anyway, since it's returned
// on event handlers by Vonage
export const getUserMedia = async (args: any) => {
    console.log('DEBUG - get user media arguments: ', args);
    const mediaStream = await OT.getUserMedia(args);
    // NOTE: getUserMedia will be used mainly to gain access to camera and mic permissions
    // (passing audio and video source "true". The resulting media stream remains active anyway,
    // and on mobiles it may lock currently selected device, preventing joining videocall.
    // Caller may want to force tracks stop to avoid this problem
    if (args.forceTrackStop) {
        mediaStream.getTracks().forEach((track) => track.stop());
    }
    return true;
}

// This will be used on waiting room, where i directly need media stream
export const getDevice = OT.getUserMedia;

// NOTE: This functions is necessary because I cannot save directly videos from videoEvents,
// target streamId may be undefined when videoEvents are generated and populated by Vonage
// asynchronously at a later time
export function getVideoFromEvents (streamId: EntityId, videoEvents: VideoCreatedEvent[]) {
    const event = videoEvents.find(({ target }) => target?.stream?.streamId === streamId);
    return event?.element;
}

// NOTE: Simple hashing to use as unique ids. For now this may be enough, consider if you need
// something more advanced
export function hashMessage ({ createdAt, sender }: Partial<ChatMessage>) {
    return `${createdAt}@${sender}`;
}

export function isEntityInactive (lastUpdateRef: RefObject<number>, threshold: number) {
    const lastUpdate = lastUpdateRef.current || 0;
    return (Date.now() - lastUpdate) > threshold;
}

export function clearInactivesFromLocalStorage ({ lastUpdateRef, callback, storageItems, threshold, time }: ClearInactiveEntityParams) {
    const interval = setInterval(() => {
        if (isEntityInactive(lastUpdateRef, threshold)) {
            const elementsDeleted = storageItems
              .map((item) => localStorage.getItem(item))
              .filter((value) => value)
              .length > 0;

            if (callback) callback(elementsDeleted);
            storageItems.forEach((item) => localStorage.removeItem(item));
            // NOTE: This is needed because event "storage" are only fired from other tabs, but I need to listen current
            // tabs events too so that react status may be updated from useEffects if needed.
            window.dispatchEvent(new Event("storage"));
        }
    }, time);
    return () => clearInterval(interval);
}

// TODO: Add worker function to utils too...