export const EVENTS_SAVE = "EVENTS_SAVE"
export const EVENTS_SET_PAGE = "EVENTS_SET_PAGE"
export const EVENTS_SET_SEARCH = "EVENTS_SET_SEARCH"
export const EVENTS_SET_SEARCH_ENTITY = "EVENTS_SET_SEARCH_ENTITY"
export const EVENTS_SET_STATUSES = "EVENTS_SET_STATUSES"
export const EVENTS_SET_MODE = "EVENTS_SET_MODE"
export const EVENTS_SET_CURRENT = "EVENTS_SET_CURRENT"
export const EVENTS_TRIGGER = "EVENTS_TRIGGER"

export const eventsSave = data => ({
  type: EVENTS_SAVE,
  payload: { data },
})

export const eventsSetPage = data => ({
  type: EVENTS_SET_PAGE,
  payload: { data },
})

export const eventsSetSearch = data => ({
  type: EVENTS_SET_SEARCH,
  payload: { data },
})

export const eventsSetSearchEntity = data => ({
  type: EVENTS_SET_SEARCH_ENTITY,
  payload: { data },
})

export const eventsSetStatuses = (data, filteredByToday) => {
  return {
    type: EVENTS_SET_STATUSES,
    payload: { data, filteredByToday },
  }
}

export const eventsSetMode = data => ({
  type: EVENTS_SET_MODE,
  payload: { data },
})

export const eventsSetCurrent = data => ({
  type: EVENTS_SET_CURRENT,
  payload: { data },
})

export const eventsTrigger = data => ({
  type: EVENTS_TRIGGER,
})
