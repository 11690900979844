import styled from "styled-components"

export const SpinnerStyled = styled.div`
  text-align: center;

  svg {
    margin: ${({ noMargin }) => (noMargin ? "0" : "60px 0px")};

    path {
      stroke: ${({ theme }) => theme.palette.primary.main};
    }
  }

  &.over {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &.fullpage {
    position: fixed;
    z-index: 9999999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.palette.common.white};
    display: flex;
    align-items: center;
    justify-content: center;

    &.hiddena {
      animation: ${({ theme }) => theme.animations.fadeOut} 0.5s forwards;
      pointer-events: none;
    }
  }
`
