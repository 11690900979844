import objectPath from "object-path"

import { delayer } from "@utils/functions"
import log from "@utils/log"

import { getCurrentLanguage } from "@locales"

import { endpoints } from "../endpoints"
import { fetcherBegin, fetcherError, fetcherSuccess } from "./actions"

// general fetcher wrapper function
function fetcher(
  path = "",
  options = {},
  callbacks = { success: () => null, error: () => null }
) {
  const { headers, body, params = {}, delay = 0, visualDelay } = options
  const { success = () => null, error = () => null } = callbacks

  const endpoint = objectPath.get(endpoints(params), path)
  if (!endpoint) throw Error(`Invalid endpoint for path ${path}`)

  const method = path && path.split(".")[0]

  const fetchOptions = {
    body: method !== "GET" ? JSON.stringify({ ...body }) : null,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: _AUTH_TOKEN_,
      lng: getCurrentLanguage(),
      ...headers,
    },
    method,
  }

  return dispatch => {
    log(`(${fetchOptions.method}) Calling ${endpoint}...`)
    dispatch(fetcherBegin(path))

    return delayer(delay).then(() =>
      fetch(endpoint, fetchOptions)
        .then(handleErrors)
        .then(res => (res.status !== 202 ? res.json() : {}))
        .then(json => {
          log(`(${fetchOptions.method}) Called ${endpoint} ===> Success!`)

          if (visualDelay) {
            setTimeout(() => {
              dispatch(fetcherSuccess(path, json, success))
            }, visualDelay)
          } else dispatch(fetcherSuccess(path, json, success))

          return json
        })
        .catch(err => {
          log(
            `(${fetchOptions.method}) Called ${endpoint} ===> ${error.message}`
          )
          dispatch(fetcherError(path, err, error))
        })
    )
  }
}

// Handle HTTP errors
const handleErrors = res => {
  let json = null
  if (!res.ok) {
    if (res.json) {
      json = res.json()
      return json.then(({ message, code }) => {
        if (code !== 200) throw Error(code)
      })
    }

    if (!json) throw Error(res.json())
  }
  return res
}

export default fetcher
