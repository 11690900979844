import React from "react"

import classnames from "classnames"
import PropTypes from "prop-types"

import Icon from "@atoms/icon"
import Spinner from "@atoms/spinner"

import { ButtonStyled } from "./button.styled"

const propTypes = {
  variant: PropTypes.string,
  disabled: PropTypes.bool,
}

const defaultProps = {
  variant: "primary",
}

const Button = ({
  variant,
  icon,
  iconPosition = "start",
  disabled,
  full,
  small,
  loading,
  animated,
  onClick,
  className,
  children,
  text,
  ...otherProps
}) => {
  return (
    <ButtonStyled
      className={classnames(
        className,
        variant,
        `text-${variant}`,
        { full: full },
        { small: small },
        { animated: animated },
        { loading: loading },
        { disabled: disabled }
      )}
      onClick={onClick && onClick}
      {...otherProps}
    >
      <Spinner
        className="loader"
        hidden={!loading}
        over
        height={24}
        width={24}
      />
      <div style={loading ? { opacity: 0 } : {}}>
        {icon && iconPosition === "start" && (
          <Icon
            className={classnames("icon", {
              iconStart: !!children,
            })}
            name={icon}
          />
        )}

        <span className={loading ? "hidden" : ""}>{children || text}</span>

        {icon && iconPosition === "end" && (
          <Icon
            className={classnames("icon", { iconEnd: !!children })}
            name={icon}
          />
        )}
      </div>
    </ButtonStyled>
  )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
