import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux"
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import { createWhitelistFilter } from "redux-persist-transform-filter"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import storage from "redux-persist/lib/storage"

import { subapps } from "@constants/enums"

import { subapp } from "@utils/functions"

import account from "./account/reducer"
import calendars from "./calendars/reducer"
import contact from "./contact/reducer"
import dialogs from "./dialogs/reducer"
import events from "./events/reducer"
import fetchs from "./fetcher/reducer"
import operators from "./operators/reducer"
import options from "./options/reducer"
import requests from "./requests/reducer"
import tenant from "./tenant/reducer"

import upload from "./videocall/uploadSlice"
import signals from "./videocall/signalsSlice"
import streams from "./videocall/streamSlice"
import devices from "./videocall/devicesSlice"
import callStatus from "./videocall/callStatusSlice"
import connections from "./videocall/connectionSlice"
import chatMessages from "./videocall/chatMessagesSlice"

export const persistWhitelist = [
  createWhitelistFilter("account", ["user"]),
  createWhitelistFilter("options", ["language"]),
  createWhitelistFilter("tenant"),
]

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["account", "requests", "events", "options", "tenant"],
  blacklist: ["callStatus"],
  transforms: persistWhitelist,
  stateReconciler: autoMergeLevel2,
}

const reducersObj = {
  account,
  calendars,
  contact,
  dialogs,
  fetchs,
  options,
  operators,
  requests,
  events,
  tenant,
  upload,
  signals,
  streams,
  devices,
  callStatus,
  connections,
  chatMessages,
}

switch (subapp) {
  case subapps.HELP:
    delete reducersObj.events
    break
  case subapps.TOUR:
    delete reducersObj.requests
    break
}

const rootReducer = combineReducers(reducersObj)

const persistedReducers = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export default store
