import ReactMarkdown from "react-markdown"

import styled from "styled-components"

import { cardDescriptionStyle } from "@atoms/card/card.styled"

export const MarkdownStyled = styled(ReactMarkdown)`
  display: inline-block;
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: 14px;

  p {
    margin-bottom: 0px;
  }

  strong {
    font-weight: ${({ theme }) => theme.typography.fontWeights.black};
    color: ${({ theme }) => theme.palette.common.black};
  }

  ${cardDescriptionStyle}

  &.policy {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 10px;
      margin-top: 30px;
      color: ${({ theme }) => theme.palette.common.black};
    }

    padding-bottom: 24px;
  }
`
