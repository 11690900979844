import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import isToday from "dayjs/plugin/isToday"
import isTomorrow from "dayjs/plugin/isTomorrow"
import localeData from "dayjs/plugin/localeData"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

import { subapps } from "@constants/enums"
import timezones from "@constants/timezones"

import { getCurrentLanguage } from "@locales"

const locales = {
  en: require("dayjs/locale/en-gb"),
  it: require("dayjs/locale/it"),
  de: require("dayjs/locale/de"),
  pl: require("dayjs/locale/pl"),
}

// TODO: remove when TOUR ready for prod
const isTour = process.env.GATSBY_SUBAPP === subapps.TOUR
// ...locales[getCurrentLanguage()] <--- correct line,
dayjs.locale({
  ...locales[isTour ? "it" : getCurrentLanguage()],
  weekStart: 1,
})
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)
dayjs.extend(localeData)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)

export const dayjsLocale = (date, locale = null) => {
  if (locale)
    dayjs.locale({
      ...locales[locale],
      weekStart: 1,
    })
  if (!date) return dayjs
  return dayjs(date)
}

export const currentTimeZone = dayjsLocale().tz.guess()

export const getTimeZoneCode = (timezone = currentTimeZone) => {
  const filteredTZs = timezones.filter(({ utc }) =>
    utc.some(u => u === timezone || "")
  )
  const [tz] = filteredTZs || []
  const { abbr } = tz || {}
  return abbr
}

export const shortNames = () =>
  dayjsLocale(null, getCurrentLanguage())
    .weekdaysShort(true)
    .map(day => day.replace(".", ""))
export const longNames = () =>
  dayjsLocale(null, getCurrentLanguage()).weekdays(true)
