export const FETCHER_BEGIN = "FETCHER_BEGIN"
export const FETCHER_SUCCESS = "FETCHER_SUCCESS"
export const FETCHER_ERROR = "FETCHER_ERROR"
export const FETCHER_CLEANUP = "FETCHER_CLEANUP"

export const fetcherBegin = path => ({
  type: FETCHER_BEGIN,
  payload: { path },
})

export const fetcherSuccess = (path, data, onSuccess) => {
  return dispatch => {
    dispatch({ type: FETCHER_SUCCESS, payload: { path, data } })
    onSuccess && onSuccess(data)
  }
}

export const fetcherError = (path, error, onError) => {
  return dispatch => {
    dispatch({ type: FETCHER_ERROR, payload: { path, error } })
    onError && onError(error)
  }
}

export const fetcherCleanup = () => ({ type: FETCHER_CLEANUP })
