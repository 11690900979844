import {
  OPERATORS_SAVE,
  OPERATORS_SET_PAGE,
  OPERATORS_SET_SEARCH,
  OPERATORS_LOCK,
  OPERATORS_TRIGGER,
  OPERATORS_TRIGGER_TEAM,
} from "./actions"

const initialState = {
  filters: {
    search: "",
    num: 20,
  },
  trigger: false,
}

function reducer(state = initialState, action) {
  const { type, payload } = action || {}
  const { data, team } = payload || {}

  switch (type) {
    case OPERATORS_SAVE:
      return {
        ...state,
        [team]: {
          ...state[team],
          list: [...data],
        },
      }

    case OPERATORS_LOCK:
      return {
        ...state,
        [team]: {
          ...state[team],
          locked: data,
        },
      }

    case OPERATORS_SET_PAGE:
      return {
        ...state,
        [team]: {
          ...state[team],
          pagination: {
            ...state.pagination,
            page: data,
          },
        },
      }

    case OPERATORS_SET_SEARCH:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: data,
        },
      }

    case OPERATORS_TRIGGER:
      return {
        ...state,
        trigger: !state.trigger,
      }

    default:
      return state
  }
}

export default reducer
