import styled, { css } from "styled-components"

import Button from "@atoms/button"

/** MIXINS */
export const cardTitleStyle = css`
  font-size: 23px;
  margin-bottom: 20px;

  &.withActions {
    padding-right: 23px;
    margin-top: 4px;
  }

  &.smallTitle {
    font-size: ${({ theme }) => theme.typography.fontSizes.normal};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    color: ${({ theme }) => theme.palette.common.black};
    line-height: 1.25;
    margin-bottom: 20px;
  }
`

export const cardDescriptionStyle = css`
  .description {
    font-size: 16px;
    line-height: 28px;
    color: ${({ theme }) => theme.palette.common.grey};
  }
`

export const cardFooterStyle = css`
  .footer {
    width: 100%;
    margin-top: 20px;

    .button {
      width: 100%;
      box-sizing: border-box;
    }
  }
`

export const cardBackStyle = css`
  font-size: 14px;
  margin-bottom: 6px;
  cursor: pointer;
  width: auto;
  padding: 4px 9px 7px 9px;
  margin-left: -8px;
  color: black;
  text-transform: none;
  font-weight: normal;

  .chevron {
    font-size: 20px;
    margin-right: 4px;
    font-weight: bold;
  }
`

/** EXPORTS */

export const CardSeparatorStyled = styled.hr`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.common.gainsboro};
  margin-bottom: 10px;
`

export const CardTitleStyled = styled.h3`
  ${cardTitleStyle}
`

export const CardBackStyled = styled(Button)`
  ${cardBackStyle}
`

export const cardStyle = css`
  border-radius: ${({ theme }) => theme.borderRadius.borderRadiusSmall};
  border: 2px ${({ theme }) => theme.palette.common.lightPink};
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 30px 36px;
  position: relative;
  width: 540px;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 24px 24px 0 ${({ theme }) => theme.palette.common.shadow};

  .actionsButtonChildren {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .reservationStatus {
    width: auto;
    display: inline-block;
    font-size: 14px;
    padding: 0px 13px;
    border-radius: 100px;
    margin-bottom: 10px;

    &.expired,
    &.cancelled {
      background-color: $color-red;
      color: white;
    }

    &.finished {
      background-color: $color-green;
    }

    &.ready {
      background-color: $color-blue;
      color: white;
    }

    &.waiting {
      background-color: $color-yellow;
      color: white;
    }
  }

  .iconCircle {
    display: block;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.ready {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }

    &.waiting {
      background-color: ${({ theme }) => theme.palette.common.yellow};
    }

    &.finished {
      background-color: ${({ theme }) => theme.palette.common.green};
    }

    &.expired,
    &.cancelled {
      background-color: ${({ theme }) => theme.palette.common.red};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 24px 18px;
  }

  .title {
    ${cardTitleStyle}
  }
  .back {
    ${cardBackStyle}
  }
  ${cardDescriptionStyle}
  ${cardFooterStyle}

  &.full {
    width: 100%;
  }
`

export const CardStyled = styled.div`
  ${cardStyle}
`
