// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-help-calendars-js": () => import("./../../../src/pages/help/calendars.js" /* webpackChunkName: "component---src-pages-help-calendars-js" */),
  "component---src-pages-help-contact-js": () => import("./../../../src/pages/help/contact.js" /* webpackChunkName: "component---src-pages-help-contact-js" */),
  "component---src-pages-help-operators-js": () => import("./../../../src/pages/help/operators.js" /* webpackChunkName: "component---src-pages-help-operators-js" */),
  "component---src-pages-help-requests-js": () => import("./../../../src/pages/help/requests.js" /* webpackChunkName: "component---src-pages-help-requests-js" */),
  "component---src-pages-help-room-deleted-js": () => import("./../../../src/pages/help/room/deleted.js" /* webpackChunkName: "component---src-pages-help-room-deleted-js" */),
  "component---src-pages-help-room-edit-js": () => import("./../../../src/pages/help/room/edit.js" /* webpackChunkName: "component---src-pages-help-room-edit-js" */),
  "component---src-pages-help-room-index-js": () => import("./../../../src/pages/help/room/index.js" /* webpackChunkName: "component---src-pages-help-room-index-js" */),
  "component---src-pages-help-tokbox-index-js": () => import("./../../../src/pages/help/tokbox/index.js" /* webpackChunkName: "component---src-pages-help-tokbox-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

