import { modes } from "@constants/enums"

import {
  REQUEST_HIGHLIGHT,
  REQUESTS_SAVE,
  REQUESTS_SET_CURRENT,
  REQUESTS_SET_MODE,
  REQUESTS_SET_PAGE,
  REQUESTS_SET_SEARCH,
  REQUESTS_SET_SEARCH_ENTITY,
  REQUESTS_SET_STATUSES,
  REQUESTS_TRIGGER
} from "./actions"

const initialState = {
  filters: {
    search: "",
    priority: "",
    statuses: [],
    filteredByToday: false,
  },
  pagination: {
    num: 20,
    page: 1,
  },
  list: [],
  mode: modes.LIVE,
  current: {},
  highlighted: "",
  trigger: false,
}

function reducer(state = initialState, action) {
  const { type, payload } = action || {}
  const { data, id, filteredByToday } = payload || {}

  switch (type) {
    case REQUESTS_SAVE:
      return {
        ...state,
        list: [...data],
      }

    case REQUESTS_SET_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: data,
        },
      }

    case REQUESTS_SET_SEARCH:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: data,
        },
      }

    case REQUESTS_SET_SEARCH_ENTITY:
      return {
        ...state,
        filters: {
          ...state.filters,
          searchEntity: data,
        },
      }

    case REQUESTS_SET_STATUSES:
      return {
        ...state,
        filters: {
          ...state.filters,
          statuses: data,
          filteredByToday,
        },
      }

    case REQUESTS_SET_MODE:
      return {
        ...state,
        mode: data,
      }

    case REQUESTS_SET_CURRENT:
      return {
        ...state,
        current: data,
      }

    case REQUESTS_TRIGGER:
      return {
        ...state,
        trigger: !state.trigger,
      }

    case REQUEST_HIGHLIGHT:
      return {
        ...state,
        highlighted: [...state.highlighted, id],
      }

    default:
      return state
  }
}

export default reducer
