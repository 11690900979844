export const REQUESTS_SAVE = "REQUESTS_SAVE"
export const REQUESTS_SET_PAGE = "REQUESTS_SET_PAGE"
export const REQUESTS_SET_SEARCH = "REQUESTS_SET_SEARCH"
export const REQUESTS_SET_SEARCH_ENTITY = "REQUESTS_SET_SEARCH_ENTITY"
export const REQUESTS_SET_STATUSES = "REQUESTS_SET_STATUSES"
export const REQUESTS_SET_MODE = "REQUESTS_SET_MODE"
export const REQUESTS_SET_CURRENT = "REQUESTS_SET_CURRENT"
export const REQUESTS_TRIGGER = "REQUESTS_TRIGGER"
export const REQUEST_HIGHLIGHT = "REQUEST_HIGHLIGHT"

export const requestsSave = data => ({
  type: REQUESTS_SAVE,
  payload: { data },
})

export const requestsSetPage = data => ({
  type: REQUESTS_SET_PAGE,
  payload: { data },
})

export const requestsSetSearch = data => ({
  type: REQUESTS_SET_SEARCH,
  payload: { data },
})

export const requestsSetSearchEntity = data => ({
  type: REQUESTS_SET_SEARCH_ENTITY,
  payload: { data },
})

export const requestsSetStatuses = (data, filteredByToday) => {
  return {
    type: REQUESTS_SET_STATUSES,
    payload: { data, filteredByToday },
  }
}

export const requestsSetMode = data => ({
  type: REQUESTS_SET_MODE,
  payload: { data },
})

export const requestsSetCurrent = data => ({
  type: REQUESTS_SET_CURRENT,
  payload: { data },
})

export const requestsTrigger = data => ({
  type: REQUESTS_TRIGGER,
})

export const requestHighlight = id => ({
  type: REQUEST_HIGHLIGHT,
  payload: { id },
})
