import { OPTIONS_SAVE } from "./actions"

const initialState = {
  calendar: {
    enabled: false,
    maxDaySlots: 0,
  },
  language: null,
}

function reducer(state = initialState, action) {
  let options = {}

  switch (action.type) {
    case OPTIONS_SAVE:
      options = { ...action.payload.data }
      return { ...state, ...options }

    default:
      return state
  }
}

export default reducer
