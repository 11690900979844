import { createSlice, createEntityAdapter, createSelector, PayloadAction, EntityId, Update } from '@reduxjs/toolkit';
import { Event, Publisher, Stream } from "@opentok/client";
import { audioEnabled, videoEnabled } from "@store/videocall/callStatusSlice";
import { RootState } from "@store/index";

const MAX_VIDEO_CONTAINER_SIZE = 2;

export type VideoCreatedEvent = Event<'videoElementCreated', Publisher> & { element: HTMLVideoElement | HTMLObjectElement; };

export interface StreamMetadata {
    id: string,
    name: string,
    connectionId: string,
    audioLevel: number,
    hasAudio: boolean,
    hasVideo: boolean,
    isPublisher: boolean,
    videoType: string,
    frameRate: number,
    videoDimensions: {
        width: number,
        height: number,
    }
}

interface StreamCreate {
    stream: Stream,
    isPublisher?: boolean,
}

interface StreamUpdate {
    stream: Stream,
    changedProperty: string,
    newValue: string | boolean | number,
}

interface ToggleUpdate {
    id: EntityId,
    value: boolean,
}

interface StreamExtraState {
    pinnedStreamId: EntityId,
    globalPinnedStreamId: EntityId,
}

const initialExtraState = {
    pinnedStreamId: '',
    globalPinnedStreamId: '',
} as StreamExtraState;

const streamsAdapter = createEntityAdapter<StreamMetadata>();
const streamSlice = createSlice({
    name: 'STREAMS',
    initialState: streamsAdapter.getInitialState(initialExtraState),
    reducers: {
        streamAdded: {
            reducer: (state, action: PayloadAction<StreamMetadata>) => {
                // NOTE: Force pin of new screen shares
                const { id, videoType } = action.payload;
                const isScreenShare = videoType === 'screen';
                if (isScreenShare) state.pinnedStreamId = id;

                streamsAdapter.addOne(state, action);
            },
            prepare: ({stream, isPublisher = false}: StreamCreate) => {
                const {streamId: id, name, hasVideo, hasAudio, videoType, frameRate, videoDimensions, connection} = stream;
                const {connectionId} = connection;
                return {
                    payload: {
                        id,
                        name,
                        hasAudio,
                        hasVideo,
                        frameRate,
                        videoType,
                        videoDimensions,
                        isPublisher,
                        audioLevel: 0,
                        connectionId,
                    },
                };
            },
        },
        streamUpdated: {
            reducer: streamsAdapter.updateOne,
            prepare: ({stream, changedProperty, newValue}: StreamUpdate) => {
                const {streamId: id} = stream;
                const changes = {[changedProperty]: newValue} as Partial<StreamMetadata>;
                return {payload: {id, changes}};
            },
        },
        streamPinned: (state, action: PayloadAction<EntityId>) => {
            const streamId = action.payload;
            const streamExists = state.ids.includes(streamId);
            // NOTE: update pinnedStream only if exists or reset it if it's a void string ''
            if (streamExists || !streamId) state.pinnedStreamId = streamId;
        },
        streamGlobalPinned: (state, action: PayloadAction<EntityId>) => {
            const streamId = action.payload;
            const streamExists = state.ids.includes(streamId);
            const userHasChangedPin = state.pinnedStreamId !== state.globalPinnedStreamId;

            // NOTE: update pinnedStream only if exists or reset it if it's a void string '' and user has not changed pin
            if (streamExists || (!streamId && !userHasChangedPin)) state.pinnedStreamId = streamId;

            // NOTE: update globalPinnedStream only if exists or reset it if it's a void string ''
            if (streamExists || !streamId) state.globalPinnedStreamId = streamId;
        },
        streamAudioLevelUpdated: {
            reducer: (state, action: PayloadAction<Update<StreamMetadata>>) => {
                const streamIds = state.ids;
                const publisherId = streamIds.find((streamId) => state.entities[streamId]?.isPublisher);

                // NOTE: if action payload has no id (streamId), it means that this event is
                // for a publisher and not a subscriber, but event does not deliver publisherId
                // so we retrieve it from state
                const id = action.payload.id || publisherId || '';
                const stream = state.entities[id];
                const audioLevel = action.payload.changes.audioLevel;

                // NOTE: Sometimes Vonage sends null as audioLevel, even if typescript definition says it's always a number
                // We ignore those updates.
                if (id && stream && audioLevel !== null) {
                    const changes = { audioLevel };
                    const update = { id, changes };
                    streamsAdapter.updateOne(state, update);
                }
            },
            prepare: ({ id, audioLevel }) => {
                return { payload: { id, changes: { audioLevel } } };
            },
        },
        streamRemoved: {
            reducer: (state, action: PayloadAction<EntityId>) => {
                // NOTE: Remove pin status if a pinned stream is removed
                const streamId = action.payload;
                if (streamId === state.pinnedStreamId) state.pinnedStreamId = '';
                if (streamId === state.globalPinnedStreamId) state.globalPinnedStreamId = '';

                streamsAdapter.removeOne(state, action);
            },
            prepare: ({streamId: id}: Stream) => ({payload: id}),
        },
        streamsCleaned: (state) => {
            state.pinnedStreamId = '';
            state.globalPinnedStreamId = '';
            streamsAdapter.removeAll(state);
        },
        streamAudioToggled: (state, action: PayloadAction<ToggleUpdate>) => {
            const { id, value } = action.payload;
            const stream = state.entities[id];
            if (stream) stream.hasAudio = value;
        },
        streamVideoToggled: (state, action: PayloadAction<ToggleUpdate>) => {
            const { id, value } = action.payload;
            const stream = state.entities[id];
            if (stream) stream.hasVideo = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(audioEnabled, (state, action: PayloadAction<boolean>) => {
                const streamIds = state.ids;
                const value = action.payload;
                const publisherId = streamIds.find((streamId) => state.entities[streamId]?.isPublisher);
                if (publisherId) {
                  const id = publisherId;
                  const updatePayload = { id, value };
                  const updateAction = streamSlice.actions.streamAudioToggled(updatePayload);
                  streamSlice.caseReducers.streamAudioToggled(state, updateAction);
                }
            })
            .addCase(videoEnabled, (state, action: PayloadAction<boolean>) => {
                const streamIds = state.ids;
                const value = action.payload;
                const publisherId = streamIds.find((streamId) => state.entities[streamId]?.isPublisher);
                if (publisherId) {
                  const id = publisherId;
                  const updatePayload = { id, value };
                  const updateAction = streamSlice.actions.streamVideoToggled(updatePayload);
                  streamSlice.caseReducers.streamVideoToggled(state, updateAction);
                }
            });
    },
});

const {
    selectById: selectStreamById,
    selectIds: selectStreamsIds,
    selectAll: selectStreams,
    selectTotal: selectStreamsLength,
} = streamsAdapter.getSelectors((state: RootState) => state.streams);

const selectVideoContainerSize = createSelector(
  [selectStreamsLength],
  (streamsLength) => Math.min(streamsLength, MAX_VIDEO_CONTAINER_SIZE) || 1,
);

const selectIsSomeStreamPinned = createSelector(
  [(state: RootState) => state.streams],
  ({ pinnedStreamId }) => Boolean(pinnedStreamId),
);

const selectIsStreamPinned = createSelector(
  [
    (state: RootState) => state.streams,
    (state: RootState, streamId: EntityId) => streamId,
  ],
  ({ pinnedStreamId }, streamId) => streamId === pinnedStreamId,
);

const selectIsStreamGlobalPinned = createSelector(
  [
      (state: RootState) => state.streams,
      (state: RootState, streamId: EntityId) => streamId,
  ],
  ({ globalPinnedStreamId }, streamId) => streamId === globalPinnedStreamId,
);

const selectIsOtherStreamPinned = createSelector(
    [selectIsStreamPinned, selectIsSomeStreamPinned],
    (isPinned, someStreamPinned) => someStreamPinned && !isPinned,
);

const selectPinnedStream = createSelector(
  [(state: RootState) => state.streams],
  ({ pinnedStreamId }) => pinnedStreamId,
);
const selectStreamVideoType = createSelector(
  [selectStreamById],
  (stream) => stream?.videoType || '',
);

const selectStreamIsScreenShare = createSelector(
  [selectStreamVideoType],
  (type) => Boolean(type && type === 'screen'),
);

const selectStreamIsPublisher = createSelector(
  [selectStreamById],
  (stream) => Boolean(stream?.isPublisher),
);

const selectStreamHasVideo = createSelector(
  [selectStreamById],
  (stream) => Boolean(stream?.hasVideo),
);

const selectStreamHasAudio = createSelector(
  [selectStreamById],
  (stream) => Boolean(stream?.hasAudio),
);

const selectStreamAudioLevel = createSelector(
  [selectStreamById],
  (stream) => stream?.audioLevel || 0,
);

const selectStreamIsMuted = createSelector(
  [selectStreamHasAudio, selectStreamIsPublisher],
  (hasAudio, isPublisher) => !hasAudio || isPublisher,
);

const selectStreamVideoDimensions = createSelector(
  [selectStreamById],
  (stream) => stream?.videoDimensions || { width: 0, height: 0 },
);

const selectStreamFrameRate = createSelector(
  [selectStreamById],
  (stream) => stream?.frameRate || 0,
);

const selectStreamParticipantName = createSelector(
  [selectStreamById],
  (stream) => stream?.name || '',
);

const selectPublisherStreamId = createSelector(
  [selectStreams],
  (streams) => {
      const publisherStream = streams.find((stream) => stream.isPublisher);
      return publisherStream?.id || '';
  }
);

const selectStreamConnectionId = createSelector(
  [selectStreamById],
  (stream) => stream?.connectionId || '',
);

const selectStreamNames = createSelector(
  [selectStreams],
  (streams) => streams.map(({ name }) => name.replace(/\s+/g, "")),
);

export {
    selectStreamsIds,
    selectStreamsLength,
    selectVideoContainerSize,
    selectIsOtherStreamPinned,
    selectIsSomeStreamPinned,
    selectIsStreamPinned,
    selectIsStreamGlobalPinned,
    selectPinnedStream,
    selectStreamVideoType,
    selectStreamIsScreenShare,
    selectStreamIsPublisher,
    selectStreamIsMuted,
    selectStreamFrameRate,
    selectStreamVideoDimensions,
    selectStreamHasVideo,
    selectStreamHasAudio,
    selectStreamAudioLevel,
    selectPublisherStreamId,
    selectStreamParticipantName,
    selectStreamConnectionId,
    selectStreamNames,
};

export const {
    streamAdded,
    streamUpdated,
    streamPinned,
    streamGlobalPinned,
    streamAudioToggled,
    streamAudioLevelUpdated,
    streamRemoved,
    streamsCleaned,
} = streamSlice.actions;

export default streamSlice.reducer;
