import { createSlice, createEntityAdapter, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { Device } from "./devicesSlice";
import { acquireDevice, fetchDevices } from "@thunks/devicesThunk";

interface DevicesExtraState { selectedAudioDevice: string }

const name = 'AUDIO_DEVICES';
const initialExtraState = { selectedAudioDevice: '' } as DevicesExtraState;

const audioDevicesAdapter = createEntityAdapter<Device>({ selectId: ((device) => device.deviceId) });

const audioDevicesSlice = createSlice({
    name,
    initialState: audioDevicesAdapter.getInitialState(initialExtraState),
    reducers: {
      audioDeviceSelected: {
        reducer: (state, action: PayloadAction<string>) => {
          state.selectedAudioDevice = action.payload;
        },
        prepare: ({ deviceId }: Device) => ({ payload: deviceId }),
      },
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchDevices.fulfilled, (state, action) => {
              const allDevices = action.payload as Device[];
              const audioDevices = allDevices
                .map((device) => ({ ...device, available: true }))
                .filter(({ label, deviceId }: Device) => (label || deviceId))
                .filter(({ kind }: Device) => kind === 'audioInput');
              const [defaultAudioDevice] = audioDevices;

              audioDevicesAdapter.setAll(state, audioDevices);
              if (defaultAudioDevice && !state.selectedAudioDevice) {
                state.selectedAudioDevice = defaultAudioDevice.deviceId;
              }
          })
          .addCase(acquireDevice.fulfilled, (state, action: PayloadAction<any, string, any>) => {
              const { audioSource } = action.meta.arg;
              if (typeof audioSource === 'string') {
                state.selectedAudioDevice = audioSource;
                audioDevicesAdapter.updateOne(state, { id: audioSource, changes: { available: true } });
              }
          })
          .addCase(acquireDevice.rejected, (state, action: PayloadAction<any, string, any, any>) => {
              const { audioSource } = action.meta.arg;
              if (typeof audioSource === 'string') {
                audioDevicesAdapter.updateOne(state, { id: audioSource, changes: { available: false } });
              }
          });
    },
});

const {
  selectById: selectDevice,
  selectAll: selectAudioDevices,
  selectIds: selectAudioDevicesIds,
  selectTotal: selectAudioDevicesCount,
} = audioDevicesAdapter.getSelectors((state: RootState) => state.devices.audioDevices);

const selectHasAudioDevices = createSelector(
  [selectAudioDevicesCount],
  (count) => count > 0,
);

const selectSelectedAudioDevice = createSelector(
  [(state: RootState) => state.devices.audioDevices],
  ({ selectedAudioDevice }) => selectedAudioDevice,
);

const selectAudioDeviceLabel = createSelector(
  [selectDevice],
  (device) => device?.label || '',
);

const selectIsAudioDeviceAvailable = createSelector(
  [selectDevice],
  (device) => Boolean(device?.available),
);

export {
    selectAudioDevices,
    selectAudioDevicesIds,
    selectHasAudioDevices,
    selectSelectedAudioDevice,
    selectAudioDeviceLabel,
    selectIsAudioDeviceAvailable,
};

export const { audioDeviceSelected } = audioDevicesSlice.actions;

export default audioDevicesSlice.reducer;
