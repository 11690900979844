import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { acquireDevice } from "@thunks/devicesThunk";
import { selectHasVideoDevices, selectSelectedVideoDevice } from "@store/videocall/videoDevicesSlice";
import { selectHasAudioDevices, selectSelectedAudioDevice } from "@store/videocall/audioDevicesSlice";

const name = 'DEVICE_PERMISSIONS';

enum PermissionsStatus {
    IDLE = 'idle',
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
}

interface DeviceState { permissionsStatus: PermissionsStatus }

const initialState = { permissionsStatus: PermissionsStatus.IDLE } as DeviceState;

const permissionsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(acquireDevice.pending, (state) => {
              state.permissionsStatus = PermissionsStatus.PENDING;
          })
          .addCase(acquireDevice.rejected, (state, action: PayloadAction<any, string, any, any>) => {
              const {audioSource, videoSource} = action.meta.arg;
              if (audioSource === true && videoSource === true) {
                  state.permissionsStatus = PermissionsStatus.REJECTED;
              }
          })
          .addCase(acquireDevice.fulfilled, (state, action: PayloadAction<any, string, any>) => {
              const {audioSource, videoSource} = action.meta.arg;
              if (audioSource || videoSource) {
                  state.permissionsStatus = PermissionsStatus.ACCEPTED;
              }
          });
    }
});

const selectIsDeviceSourceReady = createSelector(
 [selectSelectedAudioDevice, selectSelectedVideoDevice],
 (audioSource, videoSource) => Boolean(audioSource || videoSource),
);

const selectDevicesPermissionsTested = createSelector(
  [(state: RootState) => state.devices.permissions],
  ({ permissionsStatus }) => {
      const isAccepted = permissionsStatus === PermissionsStatus.ACCEPTED;
      const isRejected = permissionsStatus === PermissionsStatus.REJECTED;
      return isAccepted || isRejected;
  },
);

const selectDevicesPermissionsNeeded = createSelector(
  [selectHasVideoDevices, selectHasAudioDevices, selectDevicesPermissionsTested],
  (hasAvailableVideoDevices, hasAvailableAudioDevices, permissionsTested) => {
    return !hasAvailableVideoDevices && !hasAvailableAudioDevices && permissionsTested;
  },
);

export {
    selectIsDeviceSourceReady,
    selectDevicesPermissionsTested,
    selectDevicesPermissionsNeeded,
}

export default permissionsSlice.reducer;