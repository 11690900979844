export const TENANT_CLEANUP = "TENANT_CLEANUP"
export const TENANT_UPDATE = "TENANT_UPDATE"
export const TENANT_UPDATE_WORKING_DAYS = "TENANT_UPDATE_WORKING_DAYS"
export const TENANT_UPDATE_TIMESLOTS = "TENANT_UPDATE_TIMESLOTS"

export const tenantCleanup = () => ({ type: TENANT_CLEANUP })

export const tenantUpdate = data => ({
  type: TENANT_UPDATE,
  payload: { data },
})

export const tenantUpdateWorkingDays = (team, data) => ({
  type: TENANT_UPDATE_WORKING_DAYS,
  payload: { team, data },
})

export const tenantUpdateTimeslots = (key, data) => ({
  type: TENANT_UPDATE_TIMESLOTS,
  payload: { key, data },
})
