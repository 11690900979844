import { fetcherCleanup } from "../fetcher/actions"

export const USER_CLEANUP = "USER_CLEANUP"
export const USER_SAVE = "USER_SAVE"
export const USER_UPDATE = "USER_UPDATE"

export const RECOVERING_SAVE = "RECOVERING_SAVE"

export const userCleanup = () => ({ type: USER_CLEANUP })

// LOGIN
export const userSave = data => {
  return dispatch => {
    dispatch({ type: USER_SAVE, payload: { data } })
    // dispatch(fetcherCleanup())
  }
}

export const userUpdate = data => ({
  type: USER_UPDATE,
  payload: { data },
})

// {
//   type: "USER_UPDATE",
//   payload: { data: {token: "asd", refreshToken: "dsa"} },
// }

// RECOVER FORGOT PASSWORD
export const recoveringSave = data => {
  return dispatch => {
    dispatch({ type: RECOVERING_SAVE, payload: { data } })
    dispatch(fetcherCleanup())
  }
}
