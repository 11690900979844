import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"

import MarkdownText from "@atoms/markdowntext"

import { ErrorStyled } from "./error.styled"

const isDev = process.env.NODE_ENV === "development"

const ToastError = ({ text, onToastEnd }) => {
  const { addToast, toastStack = [] } = useToasts()

  useEffect(() => {
    if (!text) return
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    })
  }, [text])

  useEffect(() => {
    if (!toastStack.length && text) onToastEnd && onToastEnd()
  }, [toastStack])

  return null
}

const ErrorMsg = props => {
  const { t } = useTranslation()
  const {
    name,
    msg,
    toast,
    className,
    params,
    children,
    style,
    onToastEnd,
  } = props

  const parsedName =
    name &&
    ((typeof name === "object" &&
      name.message &&
      name.message.startsWith("GraphQL error: E11000")) ||
      (name.startsWith && name.startsWith("E11000")))
      ? "USER-DUPLICATE"
      : name

  const errors = t("errors", { returnObjects: true })

  let text = parsedName
    ? errors[parsedName] || msg || errors["OPERATION-FAILURE"]
    : msg

  if (isDev) {
    const { path, message } = params || {}
    const devText = path || message
    if (devText) text = text + ` (${devText})`
  }

  return toast ? (
    <ToastError text={text} onToastEnd={onToastEnd} />
  ) : text ? (
    <ErrorStyled className={className} style={style}>
      <MarkdownText>{text}</MarkdownText>
    </ErrorStyled>
  ) : children ? (
    <>{children}</>
  ) : null
}

export default ErrorMsg
