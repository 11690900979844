import React from "react"

import { MarkdownStyled } from "./markdowntext.styled"

const MarkdownText = ({ children, className, renderers = {}, onClickLink }) => {
  const isString = typeof children === "string"
  const text = isString
    ? children.replace(new RegExp("\n", "g"), "   \n")
    : children

  return (
    <MarkdownStyled
      className={className}
      renderers={{
        link: props => (
          <a
            href={props.href}
            target="_blank"
            onClick={
              onClickLink
                ? e => {
                    e.preventDefault()
                    onClickLink()
                  }
                : null
            }
          >
            {props.children}
          </a>
        ),
        ...renderers,
      }}
    >
      {text}
    </MarkdownStyled>
  )
}

export default MarkdownText
