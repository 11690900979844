import {
  CLOSE_DIALOG,
  OPEN_DIALOG,
  SET_DIALOG_DATA,
  SET_DIALOG_ON_CLOSE
} from "./actions"

const INITIAL_DIALOG = { visible: false, data: null, onClose: null }

const initialState = {
  privacyPolicy: INITIAL_DIALOG,
  pwdEdit: INITIAL_DIALOG,
  user: INITIAL_DIALOG,
  userAssign: INITIAL_DIALOG,
  userDelete: INITIAL_DIALOG,
  eventDetail: INITIAL_DIALOG,
  eventDelete: INITIAL_DIALOG,
  qrcode: INITIAL_DIALOG,
  team: INITIAL_DIALOG,
  tour: INITIAL_DIALOG,
  teamDelete: INITIAL_DIALOG,
  teamCalendar: INITIAL_DIALOG,
  teamCalendarDelete: INITIAL_DIALOG,
  payment: INITIAL_DIALOG,
  room: INITIAL_DIALOG,
  roomDelete: INITIAL_DIALOG,
  videoCall: INITIAL_DIALOG,
  languages: INITIAL_DIALOG,
  timezones: INITIAL_DIALOG,
  requestDetail: INITIAL_DIALOG,
  changeTeams: INITIAL_DIALOG,
}

function reducer(state = initialState, action) {
  const { type, payload } = action || {}
  const { key, data, onClose } = payload || {}

  switch (type) {
    case OPEN_DIALOG:
      return {
        ...state,
        [key]: {
          ...state[key],
          visible: true,
        },
      }

    case CLOSE_DIALOG:
      return {
        ...state,
        [key]: {
          ...state[key],
          visible: false,
        },
      }

    case SET_DIALOG_DATA:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: data,
        },
      }

    case SET_DIALOG_ON_CLOSE:
      return {
        ...state,
        [key]: {
          ...state[key],
          onClose: onClose,
        },
      }

    default:
      return state
  }
}

export default reducer
