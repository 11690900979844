import groupBy from "lodash/groupBy"

import {
  entities,
  languages,
  statuses,
  subapps,
  timeStatuses,
} from "@constants/enums"

import { dayjsLocale, shortNames } from "./dayjsLocale"
import {
  addSeparatorHeader,
  addSeparators,
  isValidDate,
  pad,
  subapp,
} from "./functions"

// -------------------- MISC --------------------

export class GraphQLError {
  constructor(graphQLErrors) {
    const [err] = graphQLErrors || []
    const { path: paths, data, name, message: directMessage } = err || {}
    const { messages } = data || {}
    const [internalMessage] = messages || []
    const [path] = paths || []

    this.message = internalMessage || directMessage || name
    this.path = path
    this.name = name
  }
}

// -------------------- REQUESTS --------------------

export class Request {
  constructor(item) {
    const {
      id,
      topic,
      client = {},
      createdAt,
      closedAt,
      operator,
      teams: itemTeams = [],
      status,
      priority,
    } = item
    const { fullname = "", clientId = "" } = client || {}
    const { name = "", surname = "" } = operator || {}

    const { teams } = new Teams(itemTeams, "name")
    const isClosed = status === statuses.CLOSED

    const parsedName = name && `${name.split("")[0]}.`
    const parsedSurname = surname && ` ${surname}`

    this.operatorName = `${parsedName}${parsedSurname}`
    this.clientName = fullname || clientId
    this.date = isClosed ? closedAt : createdAt
    this.topic = topic
    this.teams = teams
    this.priority = priority
    this.status = status
    this.entity = entities.REQUEST
    this.id = id
    this.origin = item
  }
}

export class Requests {
  constructor(requests) {
    if (!requests || !requests.length) {
      this.requests = []
      return
    }
    this.requests = requests.map(item => new Request(item))
  }
}

export class Reservation extends Request {
  constructor(item) {
    super(item)
    const { scheduledStartsAt, scheduledEndsAt } = item
    const starts =
      scheduledStartsAt && dayjsLocale(scheduledStartsAt) // TODO: remove replace when TimeZone is sent from contact calendar
    const ends =
      scheduledEndsAt && dayjsLocale(scheduledEndsAt) // TODO: remove replace when TimeZone is sent from contact calendar
    const slotStarts = starts && starts.format("HH:mm")
    const slotEnds = ends && ends.format("HH:mm")
    this.slot = `${slotStarts || ""} - ${slotEnds || ""}`
    this.scheduledStartsAt = scheduledStartsAt
    this.scheduledEndsAt = scheduledEndsAt
  }
}

export class Reservations {
  constructor(reservations) {
    if (!reservations || !reservations.length) {
      this.reservations = []
      return
    }
    const newReservations = reservations.map(item => new Reservation(item))

    const grouped = groupBy(
      newReservations,
      ({ scheduledStartsAt }) => scheduledStartsAt?.split("T")?.[0]
    )

    this.reservations = addSeparators(grouped)
  }
}

export class Event {
  constructor(item) {
    const {
      id,
      attendees,
      scheduledStartsAt,
      scheduledEndsAt,
      operator,
      teams,
      status,
    } = item
    const { name = "", surname = "" } = operator || {}
    const starts =
      scheduledStartsAt && dayjsLocale(scheduledStartsAt) // TODO: remove replace when TimeZone is sent from contact calendar
    const ends =
      scheduledEndsAt && dayjsLocale(scheduledEndsAt) // TODO: remove replace when TimeZone is sent from contact calendar
    const slotStarts = starts && starts.format("HH:mm")
    const slotEnds = ends && ends.format("HH:mm")

    const parsedName = name && `${name.split("")[0]}.`
    const parsedSurname = surname && ` ${surname}`

    this.tourName = teams && teams.name
    this.language = teams && teams.language
    this.operatorName = `${parsedName}${parsedSurname}`
    this.status = status
    this.attendees = attendees
    this.entity = entities.EVENT
    this.id = id
    this.origin = item
    this.slot = `${slotStarts || ""} - ${slotEnds || ""}`
    this.scheduledStartsAt = scheduledStartsAt
    this.scheduledEndsAt = scheduledEndsAt
  }
}

export class Events {
  constructor(events) {
    if (!events || !events.length) {
      this.events = []
      return
    }
    const newEvents = events.map(item => new Event(item))

    const grouped = groupBy(newEvents, ({ scheduledStartsAt }) => {
      if (!scheduledStartsAt) return
      const start = dayjsLocale(scheduledStartsAt)
      if (!start) return
      const isToday = start.isToday()
      const isTomorrow = start.isTomorrow()
      const when = isToday ? "Oggi" : isTomorrow ? "Domani" : ""
      return `${when && `${when}, `}${start.format("DD MMMM YYYY")}`
    })

    this.events = addSeparators(grouped)
  }
}

// -------------------- OPERATORS --------------------

export class Operator {
  constructor(user, teamGroup) {
    const { name, surname, role, teams: userTeams = [] } = user

    const { teams } = new Teams(userTeams, "name")
    const otherTeams = teams.filter(t => t !== teamGroup.name)

    this.userName = `${name.split("")[0]}.${
      name && surname ? " " : ""
    }${surname}`
    this.otherTeams = otherTeams
    this.role = role
    this.entity = entities.OPERATOR
    this.origin = user
  }
}

export class Operators {
  constructor(users, team) {
    const newUsers = users.map(u => new Operator(u, team))

    const { name } = team || {}

    this.operators = addSeparatorHeader(newUsers, name, {
      tableHeader: true,
      buttons: { user: [team], teamEdit: team },
    })
  }
}

export class Guides {
  constructor(users, tour) {
    const newUsers = users.map(u => new Operator(u, tour))
    const isTour = subapp === subapps.TOUR

    const { id: tourId, name, language } = tour || {}
    // TODO: remove when TOUR ready for prod
    const str = `${name} - ${
      // languages[language].long <--- correct line,
      isTour ? languages["it"].long : languages[language].long
    }`

    this.operators = addSeparatorHeader(newUsers, str, {
      tableHeader: true,
      tourId,
      buttons: { user: [tour], teamEdit: tour },
    })
  }
}

export class Attendees {
  constructor(requests) {
    this.attendees = requests.map(
      ({ id: requestId, client, videoClientUrl }) => ({
        ...client,
        requestId,
        videoClientUrl,
      })
    )
  }
}

export class Calendar {
  constructor(workingShift) {
    const { validFrom, validUntil, workingShiftDays } = workingShift

    this.validFrom = validFrom
    this.validUntil = validUntil
    this.entity = entities.CALENDAR
    this.workingShiftDays = workingShiftDays.map(e => {
      let weekDay = shortNames()[e.weekDay - 1]
      const { numberToTime: startTime } = new NumberToTime(e.startTime)
      const { numberToTime: endTime } = new NumberToTime(e.endTime)
      const { numberToTime: breakStartTime } = new NumberToTime(
        e.breakStartTime
      )
      const { numberToTime: breakEndTime } = new NumberToTime(e.breakEndTime)

      const breakStartTimeString = breakStartTime && `- ${breakStartTime}`
      const breakEndTimeString = breakEndTime && `${breakEndTime} -`

      return {
        weekDay: weekDay,
        schedule: `${startTime} ${breakStartTimeString} | ${breakEndTimeString} ${endTime}`,
      }
    })

    this.origin = workingShift
  }
}

export class Calendars {
  constructor(workingShifts, team) {
    const newShifts = workingShifts.map(shift => new Calendar(shift, team))
    const { name } = team || {}

    this.calendars = addSeparatorHeader(newShifts, name, {
      tableHeader: true,
      buttons: { calendar: team },
    })
  }
}

// -------------------- DATES --------------------

export class KeystoneDateTime {
  constructor(date, time) {
    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    this.date = `${year}-${month}-${day} ${time}`
  }
}

export class TimeSlots {
  constructor(slots) {
    if (!slots) {
      this.timeslots = []
      return
    }

    const slotsKeys = Object.keys(slots)
    const slotsValues = Object.values(slots)

    this.timeslots = slotsKeys
      .map((sk, i) => {
        const splitted = sk.split(" - ")
        const [start, end] = splitted
        const value = slotsValues[i]

        return {
          index: i,
          start,
          end,
          value,
        }
      })
      .filter(({ value }) => value > 0) // remove 0 slots
  }
}

export class WorkingDays {
  constructor(days) {
    if (!days) {
      this.workingDays = []
      return
    }

    const daysKeys = Object.keys(days)
    const daysValues = Object.values(days)

    this.workingDays = daysKeys.map((dk, i) => {
      const splitted = dk.split("-")
      const [year, day, month] = splitted
      const date = `${day}/${month}/${year}`

      const num = daysValues[i]

      return {
        date,
        num,
      }
    })
  }
}

export class TimeToNumber {
  constructor(date = new Date()) {
    const isNum = typeof date === "number"
    this.timeToNumber = isNum
      ? date
      : date
      ? Number(dayjsLocale(date).format("HHmm"))
      : null

    this.hours = date ? Number(dayjsLocale(date).format("HH")) : null
    this.minutes = date ? Number(dayjsLocale(date).format("mm")) : null
  }
}

export class NumberToTime {
  constructor(number) {
    if (!number) {
      this.numberToTime = ""
      return
    }

    const numberToString = pad(number.toString())
    const hours =
      pad(numberToString.slice(0, numberToString.length - 2)) || "00"
    const minutes = numberToString.slice(
      numberToString.length - 2,
      numberToString.length
    )

    let date = new Date()
    date.setHours(hours)
    date.setMinutes(minutes)

    this.numberToTime = `${hours}:${minutes}`
    this.numberToDate = isValidDate(number) ? number : date
  }
}

// -------------------- TEAMS --------------------

export class Teams {
  constructor(teams, key = "tag") {
    if (!teams || !teams.length) {
      this.teams = []
      return
    }

    this.teams = teams.map(t => t[key])
    this.teamsIDs = teams.map(({ id, value }) => ({ id: id || value }))
  }
}

export class TeamOptions {
  constructor(teams, allTeams, lockList = {}) {
    if (!teams || !teams.length) {
      this.options = []
      return
    }

    this.options = teams.map((t, i) => {
      let found = {}
      if (allTeams && allTeams.length) {
        const [first = {}] = allTeams.filter(a => t === a.id)
        found = first
      }
      const tag = found.tag || t.tag

      const { locked } = lockList[tag] || {}

      return {
        value: found.id || t.id,
        label: found.name || t.name,
        tag,
        locked: locked || false,
        index: i,
      }
    })
  }
}

export class LanguagesOptions {
  constructor(languages) {
    if (!languages) {
      this.options = []
      return
    }

    const lngKeys = Object.keys(languages)
    const lngValues = Object.values(languages)

    this.options =
      lngValues.map((l, i) => {
        const key = lngKeys[i]
        return {
          value: key,
          label: l.long,
          icon: `lang-${key}`,
        }
      }) || []
  }
}

export class CountriesOptions {
  constructor(countries) {
    if (!countries) {
      this.options = []
      return
    }

    const countriesKeys = Object.keys(countries)
    const countriesValues = Object.values(countries)

    this.options =
      countriesValues.map((l, i) => {
        const key = countriesKeys[i]
        return {
          value: key,
          label: l,
        }
      }) || []
  }
}

export class UsersOptions {
  constructor(users) {
    if (!users) {
      this.options = []
      return
    }

    const usersValues = Object.values(users)

    this.options =
      usersValues
        // .filter(a => a.visibility === visibilities.DELETED)
        .map((l, i) => {
          const { id, name, surname, role, visibility } = l
          // const isDeleted = visibility === visibilities.DELETED

          return {
            value: id,
            // icon: isDeleted ? "operator-deleted" : "operator",
            // iconPosition: "left",
            // iconStyle: {
            //   height: "auto",
            //   marginRight: 6,
            //   transform: "translateY(-1px)",
            // },
            label: `${name} ${surname} (${role})`,
          }
        }) || []
  }
}

export class TimeStatus {
  constructor(now, reservation, isClient, t = () => null) {
    const { scheduledStartsAt, scheduledEndsAt, status } = reservation || {}
    const nowDate = dayjsLocale(now)

    // GET UTC
    const scheduledStartDate =
      scheduledStartsAt && dayjsLocale(scheduledStartsAt) // TODO: remove replace when TimeZone is sent from contact calendar
    const scheduledEndDate =
      scheduledEndsAt && dayjsLocale(scheduledEndsAt) // TODO: remove replace when TimeZone is sent from contact calendar

    // GET DIFFS
    const minutesToStart = scheduledStartDate
      ? scheduledStartDate.diff(nowDate, "minutes")
      : 0

    const minutesToEnd = scheduledEndDate
      ? scheduledEndDate.diff(nowDate, "minutes")
      : 0

    const timeOffsets = isClient ? { max: 0, min: 0 } : { max: -5, min: 5 }

    // GET MISSING TIME TO START
    const missingDaysToStart = scheduledStartDate
      ? scheduledStartDate.diff(nowDate, "days")
      : 0

    const missingHoursToStart = scheduledStartDate
      ? scheduledStartDate
          .subtract(missingDaysToStart, "days")
          .diff(nowDate, "hours")
      : 0

    const missingMinutesToStart = scheduledStartDate
      ? scheduledStartDate
          .subtract(missingDaysToStart, "days")
          .subtract(missingHoursToStart, "hours")
          .diff(nowDate, "minutes")
      : 0

    // GET MISSING TIME TO END
    const missingDaysToEnd = scheduledEndDate
      ? scheduledEndDate.diff(nowDate, "days")
      : 0

    const missingHoursToEnd = scheduledEndDate
      ? scheduledEndDate
          .subtract(missingDaysToEnd, "days")
          .diff(nowDate, "hours")
      : 0

    const missingMinutesToEnd = scheduledEndDate
      ? scheduledEndDate
          .subtract(missingDaysToEnd, "days")
          .subtract(missingHoursToEnd, "hours")
          .diff(nowDate, "minutes")
      : 0

    // GET STATUSES
    const cancelled = status === statuses.CANCELLED
    const expired = minutesToEnd < timeOffsets.max
    const finished = status === statuses.CLOSED
    const waiting = minutesToStart > timeOffsets.min

    const timeStatus = cancelled
      ? timeStatuses.CANCELLED
      : finished
      ? timeStatuses.FINISHED
      : expired
      ? timeStatuses.EXPIRED
      : waiting
      ? timeStatuses.WAITING
      : timeStatuses.READY

    const isReady = timeStatus === timeStatuses.READY
    const isWaiting = timeStatus === timeStatuses.WAITING
    const isExpired = timeStatus === timeStatuses.EXPIRED
    const isCancelled = timeStatus === timeStatuses.CANCELLED
    const isFinished = timeStatus === timeStatuses.FINISHED
    const isLost = status === statuses.LOST
    const isCalling = status === statuses.CALLING
    const isClosed = status === statuses.CLOSED

    const missingDays = isExpired ? missingDaysToEnd : missingDaysToStart
    const missingHours = isExpired ? missingHoursToEnd : missingHoursToStart
    const missingMinutes = isExpired
      ? missingMinutesToEnd
      : missingMinutesToStart

    // EXPORT
    this.scheduledStartDate = scheduledStartDate
    this.scheduledEndDate = scheduledEndDate
    this.missingDays = missingDays
    this.missingHours = missingHours
    this.missingMinutes = missingMinutes
    this.timeStatus = timeStatus

    this.isReady = isReady
    this.isWaiting = isWaiting
    this.isExpired = isExpired
    this.isCancelled = isCancelled
    this.isFinished = isFinished
    this.isLost = isLost
    this.isCalling = isCalling
    this.isClosed = isClosed
  }
}
