import { lighten, transparentize } from "polished"

import { animations } from "@styles/animations"

const palette = {
  apa: "#4da394",
  black: "#000",
  blue: "#0063FF",
  brownGrey: "#7f7f7f",
  brownishGrey: "#707070",
  commercial: "#2750c2",
  cornflowerBlue: "#943232",
  deepSapphire: "#093572",
  elm: "#1d786f",
  gainsboro: "#E6E6E6",
  green: "#60ba00",
  grey: "#666666",
  greyDark: "#2d2d2d",
  greyDarken: "#191919",
  greyLight: "#cccccc",
  pa: "#898b8b",
  paleGrey: "#f3f4f7",
  periwinkle: "#cce0ff",
  pippin: "#ffdede",
  red: "#e02020",
  scandal: "#e3fcef",
  silver: "#C8C8C8",
  transparent: "transparent",
  turquoiseBlue: "#03addd",
  veryLightPink: "#e5e5e5",
  white: "#ffffff",
  yellow: "#dea02c",
  shadow: "rgba(14, 37, 93, 0.16)",
}

const theme = (p, a) => {
  const primary = p || palette.blue
  const accent = a || palette.blue
  return {
    palette: {
      common: palette,
      primary: {
        light: "",
        main: primary,
        dark: "",
        transparent: transparentize(0.8, lighten(0.3, primary)),
      },
      accent: {
        light: "",
        main: accent,
        dark: "",
        transparent: "",
      },
      maps: {
        common: {
          primary: {
            background: primary,
            color: palette.white,
          },
          accent: {
            background: accent,
            color: palette.white,
            border: `2px ${palette.paleGrey} solid`,
          },
          grey: {
            background: palette.paleGrey,
            color: palette.grey,
          },
          danger: {
            background: palette.red,
            color: palette.white,
          },
          success: {
            background: palette.green,
            color: palette.white,
          },
          inverse: {
            background: palette.white,
            color: primary,
          },
          inversePrimary: {
            background: palette.white,
            color: primary,
            border: `1px ${primary} solid`,
          },
          inverseAccent: {
            background: palette.white,
            color: accent,
            border: `1px ${accent} solid`,
          },
          link: {
            background: palette.transparent,
            hoverBackground: palette.transparent,
            textTransform: "none",
            color: "inherit",
            padding: 0,
          },
          transparentprimary: {
            background: transparentize(0.8, lighten(0.3, primary)),
            color: primary,
          },
        },
        contrast: {
          primary: palette.white,
        },
        icons: {
          primary: primary,
          commercial: palette.commercial,
          apa: palette.apa,
          pa: palette.pa,
        },
        iconsReal: {
          COM: palette.commercial,
          APA: palette.apa,
          PA: palette.pa,
        },
        reportType: {
          COM: palette.commercial,
          APA: palette.apa,
          PA: palette.pa,
        },
      },
      background: {
        main: palette.paleGrey,
      },
      toast: {
        errorDark: palette.cornflowerBlue,
        infoDark: palette.blue,
        successDark: palette.elm,
        error: palette.pippin,
        info: palette.periwinkle,
        success: palette.scandal,
      },
      input: {
        border: palette.brownGrey,
        borderDisabled: palette.greyLight,
        focus: primary,
        invalid: primary,
        error: palette.red,
        invalidBg: palette.paleGrey,
        invalidText: palette.brownGrey,
      },
    },
    breakpoints: {
      desktop: "1280px",
      tablet: "928px",
      tabletVertical: "640px",
      mobile: "480px",
      xsmall: "350px",
    },
    typography: {
      fontFamily: {
        primary: `'Open Sans', sans-serif`,
      },
      fontWeights: {
        thin: 100,
        extraLight: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        bolder: 800,
        black: 900,
      },
      fontSizes: {
        tiny: "0.625rem", // 10px
        small: "0.875rem", // 14px
        normal: "1rem", // 16px
        large: "1.125rem", // 18px
        big: "2rem", // 32px
        huge: "3.375rem", // 54px
      },
    },
    borderRadius: {
      borderRadiusSmall: "8px",
      borderRadiusBig: "20px",
      borderRadiusVeryBig: "24px",
    },
    sizes: {
      heightHeader: "60px",
      widthNavigation: "70px",
    },
    mixins: {},
    shadows: {},
    zIndex: {},
    animations: animations,
  }
}

export default theme
