export const getUser = state => state.account.user || {}
export const getUserRole = state => getUser(state).role || null
export const getRefreshToken = state => getUser(state).refreshToken || null
export const getUserID = state => getUser(state).id || null
export const getUserTeams = state => getUser(state).teams || []
export const getUserLanguage = state => getUser(state).language || null
export const getRecovering = state => state.account.recovering || {}
export const getCanGetUser = state => state.account.canGetUser

export const getUserToken = (state, recovering) => {
  let token
  if (recovering) {
    token = getUser(state).token || getRecovering(state).token
  } else token = getUser(state).token
  return token || null
}
