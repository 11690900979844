import { CONTACT_FORM_CLEANUP, CONTACT_FORM_UPDATE } from "./actions"

const initialState = {
  clientId: null,
  fullname: null,
  email: null,
  topic: null,
  team: null,
  date: null,
  step: 0,
  time: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case CONTACT_FORM_CLEANUP:
      return { ...initialState }

    case CONTACT_FORM_UPDATE:
      const { data } = { ...action.payload }
      return { ...state, ...data }

    default:
      return state
  }
}

export default reducer
