import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { acquireDevice } from "@thunks/devicesThunk";
import {AxiosResponse} from "axios";
import {uploadFile} from "../../thunks/cloudThunk";

export enum DrawerPositions {
  TOP = "top",
  BOTTOM = "bottom",
  RIGHT = "right",
  LEFT = "left",
}

export enum Drawers {
  CHAT = "chat",
  USERS = "users",
  NOTES = "notes",
  CLOSED = "",
}

export enum ScreenShareStatus {
  ON = 'on',
  OFF = 'off',
  STARTING = 'starting',
  STOPPING = 'stopping',
}

export type VonageErrorCode = number | null;

interface CallStatus {
  hasVideo: boolean;
  hasAudio: boolean;
  isReady: boolean;
  isJoined: boolean;
  isPublishing: boolean;
  isExiting: boolean;
  isSessionConnected: boolean;
  isSessionInitialized: boolean;
  screenShareStatus: ScreenShareStatus;
  showExitModal: boolean;
  showUploadModal: boolean;
  showSettingsModal: boolean,
  showVonageErrorModal: boolean,
  isMobileLayout: boolean,
  selectedDrawer: Drawers;
  vonageErrorCode: VonageErrorCode,
}

const initialState = {
  hasVideo: true,
  hasAudio: true,
  isReady: false,
  isJoined: false,
  isPublishing: false,
  isExiting: false,
  isSessionConnected: false,
  isSessionInitialized: false,
  screenShareStatus: ScreenShareStatus.OFF,
  showExitModal: false,
  showUploadModal: false,
  showSettingsModal: false,
  showVonageErrorModal: false,
  isMobileLayout: false,
  selectedDrawer: "",
  vonageErrorCode: null,
} as CallStatus;

const callStatusSlice = createSlice({
  name: "CALL-STATUS",
  initialState,
  reducers: {
    videoEnabled: (state, action: PayloadAction<boolean>) => {
      state.hasVideo = action.payload;
    },
    audioEnabled: (state, action: PayloadAction<boolean>) => {
      state.hasAudio = action.payload;
    },
    sessionConnected: (state, action: PayloadAction<boolean>) => {
      state.isSessionConnected = action.payload;
    },
    sessionInitialized: (state, action: PayloadAction<boolean>) => {
      state.isSessionInitialized = action.payload;
    },
    callReady: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload;
    },
    callJoined: (state, action: PayloadAction<boolean>) => {
      state.isJoined = action.payload;
    },
    callExiting: (state, action: PayloadAction<boolean>) => {
      state.isExiting = action.payload;
    },
    callPublishing: (state, action: PayloadAction<boolean>) => {
      state.isPublishing = action.payload;
    },
    screenShared: (state, action: PayloadAction<ScreenShareStatus>) => {
      state.screenShareStatus = action.payload;
    },
    drawerSelected: (state, action: PayloadAction<Drawers>) => {
      state.selectedDrawer = action.payload;
    },
    layoutMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobileLayout = action.payload;
    },
    settingsModalVisible: (state, action: PayloadAction<boolean>) => {
      state.showSettingsModal = action.payload;
    },
    uploadModalVisible: (state, action: PayloadAction<boolean>) => {
      state.showUploadModal = action.payload;
    },
    exitModalVisible: (state, action: PayloadAction<boolean>) => {
      state.showExitModal = action.payload;
    },
    vonageErrorModalVisible: (state, action: PayloadAction<boolean>) => {
      state.showVonageErrorModal = action.payload;
    },
    setVonageErrorCode: (state, action: PayloadAction<VonageErrorCode>) => {
      state.showVonageErrorModal = true;
      state.vonageErrorCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acquireDevice.rejected, (state, action: PayloadAction<any, string, any, any>) => {
        const { audioSource, videoSource } = action.meta.arg;
        const isPermissionRequest = audioSource === true && videoSource === true;
        const errorCode = action?.error?.name?.includes('OT_HARDWARE_UNAVAILABLE') ? 1501 : null;
        if (errorCode && isPermissionRequest) {
          state.vonageErrorCode = errorCode;
          state.showVonageErrorModal = true;
        }
      })
      .addCase(uploadFile.fulfilled, (state, action: PayloadAction<AxiosResponse>) => {
        const { data } = action.payload;
        if (data) state.showUploadModal = false;
      });
  }
});

export const {
  videoEnabled,
  audioEnabled,
  sessionConnected,
  sessionInitialized,
  callReady,
  callJoined,
  callPublishing,
  callExiting,
  screenShared,
  drawerSelected,
  layoutMobile,
  exitModalVisible,
  uploadModalVisible,
  settingsModalVisible,
  vonageErrorModalVisible,
  setVonageErrorCode,
} = callStatusSlice.actions;

// TODO: Consider creating selectors with createSelector
export const selectHasAudio = (state: RootState) => state.callStatus.hasAudio;
export const selectHasVideo = (state: RootState) => state.callStatus.hasVideo;
export const selectIsSessionConnected = (state: RootState) => state.callStatus.isSessionConnected;
export const selectIsSessionInitialized = (state: RootState) => state.callStatus.isSessionInitialized;
export const selectIsReady = (state: RootState) => state.callStatus.isReady;
export const selectIsCallJoined = (state: RootState) => state.callStatus.isJoined;
export const selectIsScreenShareStopping = (state: RootState) => state.callStatus.screenShareStatus === ScreenShareStatus.STOPPING;
export const selectIsScreenShareStarting = (state: RootState) => state.callStatus.screenShareStatus === ScreenShareStatus.STARTING;
export const selectIsScreenSharing = (state: RootState) => state.callStatus.screenShareStatus === ScreenShareStatus.ON;
export const selectIsExiting = (state: RootState) => state.callStatus.isExiting;
export const selectIsPublishing = (state: RootState) => state.callStatus.isPublishing;
export const selectSelectedDrawer = (state: RootState) => state.callStatus.selectedDrawer;
export const selectIsChatOpened = (state: RootState) => state.callStatus.selectedDrawer === Drawers.CHAT;
export const selectIsUsersOpened = (state: RootState) => state.callStatus.selectedDrawer === Drawers.USERS;
export const selectIsNotesOpened = (state: RootState) => state.callStatus.selectedDrawer === Drawers.NOTES;
export const selectIsLayoutHighlighted = (state: RootState) => state.callStatus.isLayoutHighlighted;
export const selectIsUploadModalVisible = (state: RootState) => state.callStatus.showUploadModal;
export const selectIsSettingsModalVisible = (state: RootState) => state.callStatus.showSettingsModal;
export const selectIsVonageErrorModalVisible = (state: RootState) => state.callStatus.showVonageErrorModal;
export const selectIsExitModalVisible = (state: RootState) => state.callStatus.showExitModal;
export const selectIsMobileLayout = (state: RootState) => state.callStatus.isMobileLayout;
export const selectVonageErrorCode = (state: RootState) => state.callStatus.vonageErrorCode;

export default callStatusSlice.reducer;
