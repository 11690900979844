import OT from "@opentok/client";
import { combineReducers } from "redux";
import permissions from "./devicePermissionsSlice";
import videoDevices from "./videoDevicesSlice";
import audioDevices from "./audioDevicesSlice";

export type Device = OT.Device & {
    available: boolean,
};

export default combineReducers({
    permissions,
    videoDevices,
    audioDevices,
});