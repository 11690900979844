import { Connection } from "@opentok/client";
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from "../../../core/store";

interface Signal {
    type?: string,
    data?: string,
    to?: Connection,
}

interface SignalMetadata extends Signal {
    creationTime: number,
}

const signalsAdapter = createEntityAdapter<SignalMetadata>(
  {
      // NOTE: Setting creationTime as id and force sorting descending, so first id is always most recent entry
      selectId: ((signal) => signal.creationTime),
      sortComparer: (a, b) => b.creationTime - a.creationTime,
  }
);

const signalsSlice = createSlice({
    name: 'SIGNALS',
    initialState: signalsAdapter.getInitialState(),
    reducers: {
        signalSent: {
            reducer: signalsAdapter.addOne,
            prepare: (signal: Signal) => {
                const creationTime = Date.now();
                return { payload: { ...signal, creationTime } };
            }
        },
        signalsCleaned: signalsAdapter.removeAll,
    },
});

const {
    selectEntities: selectSignals,
    selectIds: selectSignalsIds,
} = signalsAdapter.getSelectors((state: RootState) => state.signals);

const selectLastSignal = createSelector(
  [
    selectSignals,
    selectSignalsIds,
  ],
  (signals, ids) => signals[ids[0]] || {},
);

export { selectLastSignal };
export const { signalSent, signalsCleaned } = signalsSlice.actions;
export default signalsSlice.reducer;
