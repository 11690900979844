import {
  TENANT_CLEANUP,
  TENANT_UPDATE,
  TENANT_UPDATE_TIMESLOTS,
  TENANT_UPDATE_WORKING_DAYS
} from "./actions"

const initialState = {
  teams: [],
  workingDays: {},
  timeslots: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case TENANT_CLEANUP:
      return { ...initialState }

    case TENANT_UPDATE:
      const tenant = { ...action.payload.data }
      return { ...state, ...tenant }

    case TENANT_UPDATE_WORKING_DAYS:
      const { team } = action.payload
      const days = [...action.payload.data]
      return { ...state, workingDays: { ...state.workingDays, [team]: days } }

    case TENANT_UPDATE_TIMESLOTS:
      const { key } = action.payload
      const timeslots = [...action.payload.data]
      return { ...state, timeslots: { ...state.timeslots, [key]: timeslots } }

    default:
      return state
  }
}

export default reducer
