export const CALENDARS_SAVE = "CALENDARS_SAVE"
export const CALENDARS_SET_PAGE = "CALENDARS_SET_PAGE"
export const CALENDARS_SET_SEARCH = "CALENDARS_SET_SEARCH"
export const CALENDARS_LOCK = "CALENDARS_LOCK"
export const CALENDARS_TRIGGER = "CALENDARS_TRIGGER"
export const CALENDARS_TRIGGER_TEAM = "CALENDARS_TRIGGER_TEAM"

export const calendarsSave = (team, data) => ({
  type: CALENDARS_SAVE,
  payload: { team, data },
})

export const calendarsLock = (team, data) => ({
  type: CALENDARS_LOCK,
  payload: { team, data },
})

export const calendarsSetPage = (team, data) => ({
  type: CALENDARS_SET_PAGE,
  payload: { team, data },
})

export const calendarsSetSearch = data => ({
  type: CALENDARS_SET_SEARCH,
  payload: { data },
})

export const calendarsTrigger = data => ({
  type: CALENDARS_TRIGGER,
})
