import { createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "@store/index";
import { hashMessage } from "../../components/video-call/tokbox/utils/videocall";
import { uploadFile } from "../../thunks/cloudThunk";
import { AxiosResponse } from "axios";

export interface ChatMessage {
    metadata?: {
        url?: string
    },
    messageId?: EntityId,
    createdAt?: string,
    userId?: string,
    message: string,
    sender: string,
    hash: string,
}

interface ChatMessageMetadata extends ChatMessage {
    messageId: EntityId,
    createdAt: string,
}

interface ChatExtraState {
    readCounter: number,
    outGoingMessage: string,
    outGoingMetadata: {
        url?: string,
    }
}

const initialExtraState = {
    readCounter: 0,
    outGoingMessage: '',
    outGoingMetadata: {},
} as ChatExtraState;


const chatMessagesAdapter = createEntityAdapter<ChatMessageMetadata>(
  {
      // NOTE: To avoid duplicates, I use an hash as message id, because ids
      selectId: ((chatMessageMetadata) => chatMessageMetadata.hash),
      // NOTE: Force sorting descending, so first element is always most recent entry
      sortComparer: (a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
  }
);

const chatMessagesSlice = createSlice({
    name: 'CHAT_MESSAGES',
    initialState: chatMessagesAdapter.getInitialState(initialExtraState),
    reducers: {
        resetOutgoingMessage: (state) => {
            state.outGoingMessage = '';
            state.outGoingMetadata = {};
        },
        messageOutgoing: (state, action: PayloadAction<string>) => {
            state.outGoingMessage = action.payload;
            state.outGoingMetadata = {};
        },
        messagesRead: (state, action: PayloadAction<number>) => {
            state.readCounter = action.payload;
        },
        messageAdded: (state, action: PayloadAction<ChatMessage>) => {
            const originalMessage = action.payload;
            const { sender, messageId: originalId, metadata } = originalMessage;
            const createdAt = originalMessage.createdAt || (new Date()).toISOString();
            // NOTE: Sometimes, vonage sends duplicate signals (for example, when a new user
            // joins videocall, last signal message (even from another user) will be resent
            // I use some fake hashing (see function definition) to ensure duplicates are not pushed
            // into state, you may consider using something more advanced if needed
            const hash = hashMessage({ createdAt, sender });
            const hashList = state.ids;
            const lastHash = hashList[hashList.length -1];
            const lastMessageId = state.entities[lastHash]?.messageId?.toString() || '1';
            const nextMessageId = (parseInt(lastMessageId) + 1).toString();
            const messageId = originalId || nextMessageId;

            const message = {
                ...originalMessage,
                messageId,
                createdAt,
                sender,
                hash,
                metadata,
            };
            chatMessagesAdapter.addOne(state, message);
        },
        messagesCleaned: chatMessagesAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFile.fulfilled, (state, action: PayloadAction<AxiosResponse>) => {
                const { data } = action.payload;
                if (data) {
                    const { filename, url } = data;
                    state.outGoingMessage = filename;
                    if (url) state.outGoingMetadata = { url };
                }
            });
    }
});

const {
    selectAll: selectMessageList,
    selectTotal: selectMessagesCount,
} = chatMessagesAdapter.getSelectors((state: RootState) => state.chatMessages);

const selectLastMessage = createSelector(
    [selectMessageList],
  (messages) => messages[0] || {},
);

const selectHasMessages = createSelector(
    [selectMessagesCount],
  (length) => Boolean(length),
);

const selectOutgoingMessage = createSelector(
  [(state: RootState) => state.chatMessages],
  ({ outGoingMessage }) => outGoingMessage,
);

const selectOutgoingMetadata = createSelector(
  [(state: RootState) => state.chatMessages],
  ({ outGoingMetadata }) => outGoingMetadata,
);

const selectReadMessagesCount = createSelector(
  [(state: RootState) => state.chatMessages],
  ({ readCounter }) => readCounter,
);

const selectUnreadMessagesCount = createSelector(
  [selectMessagesCount, selectReadMessagesCount],
  (totalMessages, readMessages) => totalMessages - readMessages,
)

export {
    selectLastMessage,
    selectHasMessages,
    selectMessageList,
    selectMessagesCount,
    selectOutgoingMessage,
    selectOutgoingMetadata,
    selectUnreadMessagesCount,
};
export const {
    resetOutgoingMessage,
    messageOutgoing,
    messagesRead,
    messageAdded,
    messagesCleaned,
} = chatMessagesSlice.actions;
export default chatMessagesSlice.reducer;
