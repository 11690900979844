import styled from "styled-components"

import { fontStyle } from "@styles/fonts"

export const ErrorStyled = styled.div`
  p {
    color: ${({ theme }) => theme.palette.input.error} !important;
    margin-top: 10px;
    margin-bottom: -10px;
    margin-left: 5px;
    ${fontStyle("tiny")};
  }
`
