import {
  FETCHER_BEGIN,
  FETCHER_CLEANUP,
  FETCHER_ERROR,
  FETCHER_SUCCESS
} from "./actions"

const initialState = {}

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCHER_BEGIN:
      return {
        ...state,
        [action.payload.path]: {
          isLoading: true,
          status: "begin",
          data: {},
          error: null,
        },
      }

    case FETCHER_SUCCESS:
      return {
        ...state,
        [action.payload.path]: {
          isLoading: false,
          status: "success",
          data: { ...action.payload.data },
          error: null,
        },
      }

    case FETCHER_ERROR:
      return {
        ...state,
        [action.payload.path]: {
          isLoading: false,
          status: "error",
          data: {},
          error: action.payload.error,
        },
      }

    case FETCHER_CLEANUP:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
