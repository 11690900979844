import { createSlice, createEntityAdapter, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { Device } from "@store/videocall/devicesSlice";
import { acquireDevice, fetchDevices } from "@thunks/devicesThunk";

interface DevicesExtraState {
  selectedVideoDevice: string,
  videoDeviceCycles: number,
}

const initialExtraState = {
  selectedVideoDevice: '',
  videoDeviceCycles: 0,
} as DevicesExtraState;

const name = 'VIDEO_DEVICES';

const videoDevicesAdapter = createEntityAdapter<Device>({ selectId: ((device) => device.deviceId) });

const videoDevicesSlice = createSlice({
    name,
    initialState: videoDevicesAdapter.getInitialState(initialExtraState),
    reducers: {
      videoDeviceSelected: {
        reducer: (state, action: PayloadAction<string>) => {
          state.selectedVideoDevice = action.payload;
        },
        prepare: ({ deviceId }: Device) => ({ payload: deviceId }),
      },
      // NOTE: This action is used to manually cycle video cameras in waiting room where we cannot use vonage cycling utility
      nextVideoDeviceSelected: (state) => {
        const videoDevicesIds = state.ids.filter((id) => state.entities[id]?.kind === 'videoInput');
        const currentIndex = videoDevicesIds.findIndex(id => id === state.selectedVideoDevice);
        const nextIndex = (currentIndex + 1) % videoDevicesIds.length;
        const nextVideoDeviceId = videoDevicesIds[nextIndex];
        if (nextVideoDeviceId) state.selectedVideoDevice = nextVideoDeviceId.toString();
      },
      videoDeviceCycled: (state) => {
        state.videoDeviceCycles += 1;
      },
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchDevices.fulfilled, (state, action) => {
              const allDevices = action.payload as Device[];
              const videoDevices = allDevices
                .map((device) => ({ ...device, available: true, skipSetVideoSource: false }))
                .filter(({ label, deviceId }: Device) => (label || deviceId))
                .filter(({ kind }: Device) => kind === 'videoInput');

              videoDevicesAdapter.setAll(state, videoDevices);
          })
          .addCase(acquireDevice.fulfilled, (state, action: PayloadAction<any, string, any>) => {
              const { videoSource } = action.meta.arg;
              if (typeof videoSource === 'string') {
                state.selectedVideoDevice = videoSource;
                videoDevicesAdapter.updateOne(state, { id: videoSource, changes: { available: true } });
              }
          })
          .addCase(acquireDevice.rejected, (state, action: PayloadAction<any, string, any, any>) => {
              const { videoSource } = action.meta.arg;
              if (typeof videoSource === 'string') {
                videoDevicesAdapter.updateOne(state, { id: videoSource, changes: { available: false } });
              }
          });
    },
});

const {
  selectIds: selectVideoDevicesIds,
  selectById: selectDevice,
  selectAll: selectVideoDevices,
  selectTotal: selectVideoDevicesCount,
} = videoDevicesAdapter.getSelectors((state: RootState) => state.devices.videoDevices);

const selectHasVideoDevices = createSelector(
  [selectVideoDevicesCount],
  (count) => count > 0,
);

const selectSelectedVideoDevice = createSelector(
  [(state: RootState) => state.devices.videoDevices],
  ({ selectedVideoDevice }) => selectedVideoDevice,
);

const selectVideoDeviceLabel = createSelector(
  [selectDevice],
  (device) => device?.label || '',
);

const selectIsVideoDeviceAvailable = createSelector(
  [selectDevice],
  (device) => Boolean(device?.available),
);

const selectVideoDeviceCycles = createSelector(
  [(state: RootState) => state.devices.videoDevices],
  ({ videoDeviceCycles }) => videoDeviceCycles,
);

export {
    selectVideoDevices,
    selectVideoDevicesIds,
    selectHasVideoDevices,
    selectSelectedVideoDevice,
    selectVideoDeviceLabel,
    selectIsVideoDeviceAvailable,
    selectVideoDeviceCycles,
};

export const {
  videoDeviceSelected,
  videoDeviceCycled,
  nextVideoDeviceSelected,
} = videoDevicesSlice.actions;

export default videoDevicesSlice.reducer;
