import { darken } from "polished"
import styled, {
  css,
  keyframes
} from "styled-components"

const growAnim = keyframes`from { width: 0%; } to { width: 100% }`
const slideFromLeftAnim = keyframes`0% { opacity: 0; transform: translateX(-100px); } 100% { opacity: 1; transform: translateX(0); }`
const slideToLeftAnim = keyframes`0% { opacity: 1; transform: translateX(0); } 100% { opacity: 0; transform: translateX(-100px); }`

export const ToastStyled = styled.div`
  width: 400px;
  border-radius: ${({ theme }) => theme.borderRadius.borderRadiusSmall};
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 10px 0 rgba(14, 37, 93, 0.08);
  margin-left: ${({ theme }) => theme.sizes.widthNavigation} + 10;
  display: flex;
  vertical-align: middle;
  justify-content: space-between;

  animation: ${({ dismissing, toastAnimDuration }) =>
    dismissing
      ? css`
          ${slideToLeftAnim} ${toastAnimDuration}ms ease-in
        `
      : css`
          ${slideFromLeftAnim} ${toastAnimDuration}ms ease-in
        `};
  animation-fill-mode: forwards;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVertical}) {
    margin-left: 10px;
  }

  .message {
    margin-left: 46px;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    padding: 15px 10px;
    max-width: 240px;
  }

  .button {
    padding: 0px;
    margin-right: 15px;
    height: 37px;
    align-self: center;
    text-align: center;
    line-height: 10px;
    overflow: hidden;

    div {
      z-index: 10;
      position: relative;
      pointer-events: none;
      padding: 10px;
    }
  }

  .side {
    display: flex;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 40px;
    align-items: center;
    vertical-align: middle;

    .icon {
      margin: 0 auto;
    }
  }

  &.info {
    background-color: ${({ theme }) => theme.palette.toast.info};

    p {
      color: ${({ theme }) => theme.palette.toast.infoDark};
    }

    .side,
    .button {
      background-color: ${({ theme }) => theme.palette.toast.infoDark};
    }

    .countdown {
      background-color: ${({ theme }) =>
        darken(0.1, theme.palette.toast.infoDark)};
    }
  }

  &.success {
    background-color: ${({ theme }) => theme.palette.toast.success};

    p {
      color: ${({ theme }) => theme.palette.toast.successDark};
    }

    .side,
    .button {
      background-color: ${({ theme }) => theme.palette.toast.successDark};
    }

    .countdown {
      background-color: ${({ theme }) =>
        darken(0.1, theme.palette.toast.successDark)};
    }
  }

  &.error {
    background-color: ${({ theme }) => theme.palette.toast.error};

    p {
      color: ${({ theme }) => theme.palette.toast.errorDark};
    }

    .side,
    .button {
      background-color: ${({ theme }) => theme.palette.toast.errorDark};
    }

    .countdown {
      background-color: ${({ theme }) =>
        darken(0.1, theme.palette.toast.errorDark)};
    }
  }
`

export const CountDownStyled = styled.div`
  &.countdown {
    bottom: 0;
    width: 100%;
    left: 0;
    position: absolute !important;
    height: 100%;
    z-index: 1 !important;
    transition: opacity 0.2s ease-in-out;

    animation: ${({ autoDismissTimeout }) => css`
      ${growAnim} ${autoDismissTimeout}ms ease-in
    `};
    animation-play-state: ${({ isRunning }) =>
      isRunning ? "running" : "paused"};
    opacity: ${({ opacity }) => opacity};
  }
`
